import React from 'react'
import Box, { BoxProps } from '../components/Box'
import Text from '../components/Text'
import { HStack, Container } from './Layout'
import { Logo, Menu as MenuIcon, User } from './Icon'
import Link from './Link'
import { MenuButton, Menu, MenuList, MenuLink, MenuItem } from './Menu'
import Input from './Input'
import { UserContext } from '../App'
import { Translated } from '../context/LocaleContext'

interface HeaderProps extends BoxProps {
    links: {
        path: string
        label: string
    }[]
}

export const HeaderUser = () => {
    const { user: currentUser, updateLegalEntity, logout } = React.useContext(UserContext)

    if (!currentUser) {
        return null
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <Menu>
                <MenuButton>
                    <HStack spacing={1} alignItems="center" justifyContent="flex-end">
                        <Text fontSize="xs" m={0} fontWeight="semibold">
                            {currentUser.user_email}
                        </Text>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="100%"
                            bg="offwhite"
                            width={32}
                            height={32}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="100%"
                                bg="cherry"
                                width={22}
                                height={22}
                            >
                                <User />
                            </Box>
                        </Box>
                    </HStack>
                </MenuButton>
                <MenuList>
                    <MenuItem
                        onSelect={() => logout && logout()}
                        css={{
                            textDecoration: 'none',
                            color: 'black',
                            display: 'block'
                        }}
                    >
                        {Translated('logout')}
                    </MenuItem>
                    {currentUser.allowedentity.map((x: any) => (
                        <MenuItem
                            onSelect={() => {
                                updateLegalEntity(x.id).then(() => {
                                    window.location.reload()
                                })
                            }}
                            css={{
                                textDecoration: 'none',
                                color: 'black',
                                display: 'block'
                            }}
                        >
                            <Input.Radio py={2} checked={Number(x.id) === currentUser.legal_entity_id}>
                                {x.name}
                            </Input.Radio>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    )
}

const Header: React.FC<HeaderProps> = ({ links, children, ...props }) => {
    return (
        <Box display="grid" gridTemplateColumns="214px 2fr 1fr" gridGap={2} p={3} as={Container}>
            <HStack
                width={214}
                height={32}
                justifyContent="space-between"
                borderRight={1}
                borderRightColor="offwhite"
                alignItems="center"
            >
                <Link state={{}} to="/">
                    <Logo />
                </Link>
                <Menu>
                    <MenuButton>
                        <HStack alignItems="center" spacing={2} height={32} pr={2}>
                            <Text fontSize="sm" mb={0} fontWeight="semibold">
                                MENU
                            </Text>
                            <MenuIcon />
                        </HStack>
                    </MenuButton>
                    <MenuList>
                        {links.map(x => (
                            <MenuLink
                                href="/#/timeline"
                                css={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    display: 'block'
                                }}
                            >
                                {x.label}
                            </MenuLink>
                        ))}
                    </MenuList>
                </Menu>
            </HStack>
            {children}
            <HeaderUser />
        </Box>
    )
}

export default Header
