import React from 'react'
import { Link as ReachLink, NavLink as RouterNavLink } from 'react-router-dom'

const Link: React.FunctionComponent<{
    to: string
    state: any
    className?: string
    activeClassName?: string
    draggable?: boolean
}> = ({ to, state, className, children, draggable, activeClassName }) => (
    <ReachLink
        to={to}
        state={state}
        activeClassName={activeClassName}
        className={className}
        children={children}
        draggable={draggable}
    />
)

export const NavLink: React.FunctionComponent<{
    to: string
    state: any
    exact?: boolean
    className?: string
    activeClassName?: string
    // @t
}> = ({ to, state, exact = false, className, children, activeClassName }) => {
    return (
        <RouterNavLink
            to={to}
            state={state}
            activeClassName={activeClassName}
            exact={exact}
            className={className}
            children={children}
        />
    )
}

export default Link
