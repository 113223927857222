import React from 'react'
import sv from '../locales/sv.json'
import en from '../locales/en.json'

interface Locales {
    [key: string]: Record<string, string>
}

type LocaleKeys = keyof typeof sv

const locales: Locales = {
    sv,
    en
}

export const formatString = (str: string, ...args: string[]) => {
    for (const arg in args) {
        if (arg) {
            str = str.replace('{' + arg + '}', args[arg])
        }
    }
    return str.replace(/\n/g, '<br />')
}

const supportedLanguages = ['en']

export const getLanguage = () => {
    if (supportedLanguages.indexOf(navigator.language) !== -1) {
        return navigator.language
    }
    return supportedLanguages[0]
}

type ContextType = {
    [key in LocaleKeys]: string
}

export const Translated = (children: LocaleKeys, args?: string[] | number[]) => {
    const locale = locales[getLanguage()] as ContextType

    let copy = locale[children]

    if (copy && args) {
        args.forEach((x: string | number, index: number) => {
            copy = copy.replace(`{{${index}}}`, String(x))
        })
    }

    return copy || children
}

export const useLocale = () => {
    const locale = locales[getLanguage()] as ContextType
    return locale
}
