import React from 'react'
import Box, { BoxProps } from './Box'

export const CheckboxEmpty: React.FC<BoxProps> = props => {
    return (
        <Box as="svg" display="block" width={24} height={24} color="black" {...props}>
            <path
                d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                fill="currentColor"
            />
            <path d="M0 0h24v24H0z" fill="none" />
        </Box>
    )
}

export const CheckboxFilled: React.FC<BoxProps> = props => {
    return (
        <Box as="svg" display="block" width={24} height={24} color="black" {...props}>
            <path d="M4 12.828l1.414-1.414 4.95 4.95-1.414 1.414L4 12.828z" fill="#000" />
            <path d="M10.364 16.364L8.95 14.95 17.5 7 19 8.5l-8.636 7.864z" fill="#000" />
        </Box>
    )
}

export const CheckCircle: React.FC<BoxProps> = props => {
    return (
        <Box as="svg" width={24} height={24} color="black" {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                fill="currentColor"
            />
        </Box>
    )
}

export const ErrorCircle: React.FC<BoxProps> = props => {
    return (
        <Box as="svg" width={24} height={24} color="black" {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                fill="currentColor"
            />
        </Box>
    )
}

export const InfoCircle: React.FC<BoxProps> = props => {
    return (
        <Box as="svg" width={24} height={24} color="black" {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                fill="currentColor"
            />
        </Box>
    )
}

export const Scissor: React.FC<BoxProps> = props => {
    return (
        <Box as="svg" width={17} height={16} viewBox="0 0 17 16" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6.476 3.2C6.476 1.44 5.02 0 3.238 0 1.458 0 0 1.44 0 3.2c0 1.76 1.457 3.2 3.238 3.2.486 0 .89-.08 1.295-.32L6.476 8 4.533 9.92c-.404-.24-.81-.32-1.295-.32C1.458 9.6 0 11.04 0 12.8 0 14.56 1.457 16 3.238 16s3.238-1.44 3.238-3.2c0-.48-.08-.88-.324-1.28L8.095 9.6l5.667 5.6H17L6.152 4.48c.243-.4.324-.8.324-1.28zM3.238 4.8c-.89 0-1.619-.72-1.619-1.6 0-.88.729-1.6 1.62-1.6.89 0 1.618.72 1.618 1.6 0 .88-.728 1.6-1.619 1.6zm0 9.6c-.89 0-1.619-.72-1.619-1.6 0-.88.729-1.6 1.62-1.6.89 0 1.618.72 1.618 1.6 0 .88-.728 1.6-1.619 1.6zm4.857-6.8c.243 0 .405.16.405.4 0 .24-.162.4-.405.4-.243 0-.405-.16-.405-.4 0-.24.162-.4.405-.4zM17 .8h-3.238L8.905 5.6l1.619 1.6L17 .8z"
            />
        </Box>
    )
}

export const Pen: React.FC<BoxProps> = props => (
    <Box as="svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0 12.622V16h3.378l9.778-9.867-3.378-3.377L0 12.622zm15.733-9.066a.86.86 0 0 0 0-1.245L13.69.267a.86.86 0 0 0-1.245 0l-1.6 1.6 3.378 3.377 1.511-1.688z"
        />
    </Box>
)

export const Erase: React.FC<BoxProps> = props => (
    <Box as="svg" width={18} height={15} viewBox="0 0 18 15" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.053 13.571h4.759l1.333-1.317a.521.521 0 00.001-.74L6.188 7.582a.533.533 0 00-.747 0l-3.346 3.304a.521.521 0 00-.001.74l1.959 1.945zm6.352 0H18V15H3.714l-3.56-3.536a.521.521 0 010-.74L10.857.152a.533.533 0 01.747.001l5.804 5.764a.521.521 0 01-.002.74l-7 6.914z"
        />
    </Box>
)

export const Undo: React.FC<BoxProps> = props => (
    <Box as="svg" width={14} height={18} viewBox="0 0 14 18" {...props}>
        <path
            fill="#333"
            fillRule="evenodd"
            d="M7 3.6V0L2.625 4.5 7 9V5.4c2.887 0 5.25 2.43 5.25 5.4S9.887 16.2 7 16.2s-5.25-2.43-5.25-5.4H0C0 14.76 3.15 18 7 18s7-3.24 7-7.2-3.15-7.2-7-7.2z"
        />
    </Box>
)

export const Triangle: React.FC<BoxProps> = props => (
    <Box as="svg" width={20} height={16} viewBox="0 0 20 16" {...props}>
        <path fill="none" stroke="#333" strokeWidth={1.455} d="M10 2l8 13H2z" />
    </Box>
)

export const Menu: React.FC<BoxProps> = props => (
    <Box as="svg" width={16} color="black" height={10} viewBox="0 0 16 10" fill="none" {...props}>
        <path fill="currentColor" d="M0 0H16V2H0z" />
        <path fill="currentColor" d="M0 4H16V6H0z" />
        <path fill="currentColor" d="M0 8H16V10H0z" />
    </Box>
)

export const Logo: React.FC<BoxProps> = props => (
    <Box as="svg" width={18} height={24} viewBox="0 0 18 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#969796"
                d="M8.384 10.376L.756 10.4 0 16.263h4.64l.258-2.161h.994c2.279 0 2.792.523 4.653 4.808l2.086 4.808h5.134l-4.918-10.615c-.928-1.453-2.306-2.765-4.463-2.727"
            />
            <path
                fill="#C11E43"
                d="M9.583 0h-7.51L.929 8.984h4.636l.648-5 .001-.01h2.279c2.31 0 3.657 1.143 3.657 3.014a3.11 3.11 0 01-1.174 2.464c2.373.905 3.225 3.04 3.26 3.018 1.852-1.154 2.728-3.181 2.728-5.344 0-2.91-1.83-7.126-7.38-7.126"
            />
        </g>
    </Box>
)

export const Calendar: React.FC<BoxProps> = props => (
    <Box as="svg" width={14} color="black" height={16} viewBox="0 0 14 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11 9H7v4h4V9zm-.889-7.4H3.89V0H2.333v1.6H0V16h14V1.6h-2.333V0H10.11v1.6zm2.333 12.8H1.556V5.6h10.888v8.8z"
        />
    </Box>
)

export const User: React.FC<BoxProps> = props => (
    <Box as="svg" width={12} color="white" height={12} viewBox="0 0 12 12" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6 6c1.65 0 3-1.35 3-3S7.65 0 6 0 3 1.35 3 3s1.35 3 3 3zm0 1.5c-2.025 0-6 .975-6 3V12h12v-1.5c0-2.025-3.975-3-6-3z"
        />
    </Box>
)

export const ArrowDown: React.FC<BoxProps> = props => (
    <Box as="svg" width={12} height={8} viewBox="0 0 12 8" color="black" {...props}>
        <path fill="currentColor" fillRule="evenodd" d="M10.6 0L6 4.6 1.4 0 0 1.4l6 6 6-6z" />
    </Box>
)

export const Visibility: React.FC<BoxProps> = props => (
    <Box as="svg" color="black" width={17} height={11} viewBox="0 0 17 11" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.5 0C4.636 0 1.314 2.273 0 5.5 1.314 8.727 4.636 11 8.5 11s7.186-2.273 8.5-5.5C15.686 2.273 12.364 0 8.5 0zm0 9.167c-2.164 0-3.864-1.614-3.864-3.667s1.7-3.667 3.864-3.667c2.164 0 3.864 1.614 3.864 3.667s-1.7 3.667-3.864 3.667zm0-5.867c-1.314 0-2.318.953-2.318 2.2 0 1.247 1.004 2.2 2.318 2.2s2.318-.953 2.318-2.2c0-1.247-1.004-2.2-2.318-2.2z"
        />
    </Box>
)

export const Sort: React.FC<BoxProps> = props => (
    <Box as="svg" color="black" width={18} height={12} viewBox="0 0 18 12" {...props}>
        <path fill="currentColor" fillRule="evenodd" d="M0 12h6v-2H0v2zM0 0v2h18V0H0zm0 7h12V5H0v2z" />
    </Box>
)

export const Warning: React.FC<BoxProps> = props => (
    <Box as="svg" width={11} height={9} viewBox="0 0 11 9" {...props}>
        <path fill="#D00030" fillRule="evenodd" stroke="#FAFAFA" d="M5.5 0L11 9H0z" />
    </Box>
)

export const Add: React.FC<BoxProps> = props => (
    <Box as="svg" width={20} height={20} viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#777" d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z" />
            <path fill="#FAFAFA" d="M11 5H9v4H5v2h4v4h2v-4h4V9h-4z" />
        </g>
    </Box>
)

export const BigAdd: React.FC<BoxProps> = props => (
    <Box as="svg" width={32} height={32} viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#bed78a" d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0z" />
            <path fill="#FAFAFA" d="M11 5H9v4H5v2h4v4h2v-4h4V9h-4z" />
        </g>
    </Box>
)

export const Back: React.FC<BoxProps> = props => (
    <Box as="svg" width={15} height={24} viewBox="0 0 15 24" {...props}>
        <path fill="#757575" fillRule="evenodd" d="M14.8 2.8L12 0 0 12l12 12 2.8-2.8L5.6 12z" />
    </Box>
)

export const Forward: React.FC<BoxProps> = props => (
    <Box as="svg" width={15} height={24} viewBox="0 0 15 24" {...props}>
        <path fill="#757575" fillRule="evenodd" d="M0 2.8L2.8 0l12 12-12 12L0 21.2 9.2 12z" />
    </Box>
)
