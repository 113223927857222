import React from 'react'
import Box from '../components/Box'
import { useAsyncAbortable } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import { TableRow, TableHeader, TableColumn } from '../components/Table'
import { Touchable } from '../components/Button'
import { BigAdd } from '../components/Icon'
import Link from '../components/Link'

interface PageProps {}

const UsersPage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {
    const data = useAsyncAbortable(signal => Admin.getUsers(signal), [])

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {data.result && (
                <Box as="table" mt={4} css={{ borderCollapse: 'collapse' }} width={750} mx="auto">
                    <tbody>
                        <TableRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Phone</TableHeader>
                            <TableHeader>Email</TableHeader>
                            <TableHeader>Default machine</TableHeader>
                            <TableHeader textAlign="right">
                                <Touchable as={Link} to="/admin/users/add">
                                    <BigAdd></BigAdd>
                                </Touchable>
                            </TableHeader>
                        </TableRow>

                        {data.result.map(user => (
                            <TableRow key={user.id}>
                                <TableColumn css={{ a: { textDecoration: 'none', color: '#83ab35' } }}>
                                    <Box as={Link} to={'/admin/users/' + user.id}>
                                        {user.name}
                                    </Box>
                                </TableColumn>
                                <TableColumn>{user.phone}</TableColumn>
                                <TableColumn>{user.email}</TableColumn>
                                <TableColumn>{user.defaultmachinename}</TableColumn>
                                <TableColumn></TableColumn>
                            </TableRow>
                        ))}
                    </tbody>
                </Box>
            )}
        </Box>
    )
}

export default UsersPage
