import React from 'react'
import Box, { BoxProps } from './Box'
import { ResponsiveValue } from 'styled-system'

export const Container: React.FunctionComponent<BoxProps> = props => <Box {...props} />

export type StackProps = {
    spacing?: ResponsiveValue<number | string>
} & BoxProps

type VStack = React.FunctionComponent<StackProps>
type HStack = React.FunctionComponent<StackProps>

export const VStack: VStack = ({ spacing = 3, css, ...props }) => {
    return (
        <Box
            className="VStack"
            display="flex"
            alignSelf="stretch"
            flex="1 1 auto"
            flexDirection="column"
            css={{
                '> *': {
                    m: 0
                },
                '> *:not(:last-child)': {
                    mb: spacing
                },
                '> .Divider': {
                    height: '1px'
                },
                ...css
            }}
            {...props}
        />
    )
}

export const HStack: HStack = ({ spacing = 3, css, ...props }) => (
    <Box
        className="HStack"
        display="flex"
        alignSelf="stretch"
        alignItems="center"
        flexDirection="row"
        css={{
            '> *': {
                m: 0
            },
            '> *:not(:last-child)': {
                mr: spacing
            },
            '> .Divider': {
                width: '1px'
            },
            ...css
        }}
        {...props}
    />
)

export const Spacer: React.FunctionComponent<BoxProps> = props => <Box className="Spacer" flex="1" {...props} />
export const Divider: React.FunctionComponent<BoxProps> = props => (
    <Box className="Divider" bg="brownish-grey" alignSelf="stretch" {...props} />
)

VStack.displayName = 'VStack'
HStack.displayName = 'HStack'
Spacer.displayName = 'Spacer'
Divider.displayName = 'Divider'
