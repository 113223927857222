import React, { ReactElement } from 'react'
import Tippy from '@tippy.js/react'
import Box, { BoxProps } from './Box'

interface TooltipProps extends BoxProps {
    content: any
    disabled?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({ children, css, disabled = false, content, ...props }) => {
    const child = React.Children.only(children)
    if (disabled || !content || (Array.isArray(content) && content.length === 0)) {
        return React.cloneElement(child as ReactElement, {})
    }

    return (
        <Box
            as={Tippy}
            css={{
                bg: 'white',
                color: 'black',
                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)',
                borderRadius: 0,
                ...css
            }}
            animation="fade"
            animateFill={false}
            distance={7}
            hideOnClick={false}
            content={content}
            delay={0}
            {...props}
        >
            {child ? React.cloneElement(child as ReactElement, {}) : null}
        </Box>
    )
}

export default Tooltip
