import React from 'react'
import { scaleTime, ScaleTime } from 'd3-scale'
import isSaturday from 'date-fns/is_saturday'
import isSunday from 'date-fns/is_sunday'
import format from 'date-fns/format'
import startOfDay from 'date-fns/start_of_day'
import differenceInDays from 'date-fns/difference_in_days'
import isFirstDayOfMonth from 'date-fns/is_first_day_of_month'
import isMonday from 'date-fns/is_monday'
import eachDay from 'date-fns/each_day'

import Box, { BoxProps } from './Box'
import Text from './Text'
import { HStack } from './Layout'
import Datepicker from './Datepicker'
import Button from './Button'
import { Calendar } from './Icon'
import { getSteppedValue } from './Draggable'
import { Translated } from '../context/LocaleContext'

const isHoliday = (d: Date) => isSaturday(d) || isSunday(d)

interface MonthsProps extends BoxProps {
    months: Date[]
    scale: ScaleTime<number, number>
}

export const Months: React.FC<MonthsProps> = ({ months, scale }) => (
    <Box position="relative" height={14} width="100%" mb={1}>
        {months.map((x, index) => (
            <Box
                key={index}
                top={0}
                height={14}
                style={{
                    left: scale(startOfDay(x))
                }}
                position="absolute"
                textAlign="center"
                css={{
                    textTransform: 'uppercase'
                }}
            >
                <Text fontSize="xs" color="brownish-grey" fontWeight="bold" m={0}>
                    {format(x, 'MMMM YYYY')}
                </Text>
            </Box>
        ))}
    </Box>
)

interface WeeksProps extends BoxProps {
    weeks: Date[]
    scale: ScaleTime<number, number>
    dayWidth: number
}

export const Weeks: React.FC<WeeksProps> = ({ weeks, scale, dayWidth }) => (
    <Box position="relative" width="100%" height={14} mb={1}>
        {weeks.map((x, index) => (
            <Box
                key={index}
                top={0}
                height={14}
                style={{
                    left: scale(startOfDay(x)),
                    width: dayWidth
                }}
                position="absolute"
                textAlign="center"
            >
                <Text fontSize="xs" color="midgrey" fontWeight="semibold" m={0}>
                    {'v.' + format(x, 'W')}
                </Text>
            </Box>
        ))}
    </Box>
)

interface DaysProps extends BoxProps {
    days: Date[]
    scale: ScaleTime<number, number>
    dayWidth: number
}

export const Days: React.FC<DaysProps> = ({ days, scale, dayWidth }) => (
    <Box className="Days" height="2.8125rem" width="100%" bg="white" zIndex={2} position="relative">
        {days.map((x, index) => (
            <Box
                key={index}
                top={0}
                my={1}
                style={{
                    width: dayWidth,
                    left: scale(startOfDay(x))
                }}
                position="absolute"
                textAlign="center"
                css={{
                    textTransform: 'uppercase'
                }}
            >
                <Text
                    fontSize="xs"
                    m={0}
                    lineHeight="1.33"
                    fontWeight="semibold"
                    color={isHoliday(x) ? 'cherry' : 'midgrey'}
                >
                    {format(x, 'ddd')}
                </Text>
                <Text fontSize="xs" color={isHoliday(x) ? 'cherry' : 'brownish-grey'} fontWeight="bold" m={0}>
                    {format(x, 'DD')}
                </Text>
            </Box>
        ))}
    </Box>
)

interface DateInfo {
    date: string
    week: number
}

interface DatesProps extends BoxProps {
    start: Date
    end: Date
    dateinfo?: DateInfo[]
}

const Dates: React.FC<DatesProps> = ({ start, end, dateinfo }) => {
    const range = [start, end]
    const width = getSteppedValue(differenceInDays(range[1], range[0]) * 40, 40)

    const scale = scaleTime()
        .domain(range)
        .rangeRound([0, width])

    const days = eachDay(range[0], range[1])
    const dayWidth = scale(startOfDay(days[1])) - scale(startOfDay(days[0]))

    const months = days.filter(x => isFirstDayOfMonth(x))
    const weeks = days.filter(x => isMonday(x))

    return (
        <Box>
            <Months months={months} scale={scale} />
            <Box position="relative" width="100%" height={14} mb={1}>
                {dateinfo &&
                    dateinfo.map(x => (
                        <Box
                            key={x.date}
                            top={0}
                            height={14}
                            style={{
                                left: scale(startOfDay(x.date)),
                                width: dayWidth
                            }}
                            position="absolute"
                            textAlign="center"
                        >
                            <Text fontSize="xs" color="midgrey" fontWeight="semibold" m={0}>
                                {Translated('week', [x.week])}
                            </Text>
                        </Box>
                    ))}
            </Box>
            <Days days={days} scale={scale} dayWidth={dayWidth} />
        </Box>
    )
}

export const DatesWithDaysOnly: React.FC<DatesProps> = ({ start, end }) => {
    const range = [start, end]

    const width = getSteppedValue(differenceInDays(range[1], range[0]) * 40, 40)

    const scale = scaleTime()
        .domain(range)
        .rangeRound([0, width])

    const days = eachDay(range[0], range[1])
    const dayWidth = scale(startOfDay(days[1])) - scale(startOfDay(days[0]))

    return <Days days={days} scale={scale} dayWidth={dayWidth} />
}

interface TimelineDatepickerProps extends BoxProps {
    start: Date
    end: Date
    onDateChange: (d: Date) => void
}

export const TimelineDatepicker: React.FC<TimelineDatepickerProps> = ({ start, end, onDateChange, ...props }) => {
    const calendarRef = React.createRef<HTMLElement>()

    return (
        <HStack
            spacing={1}
            px={2}
            py={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            borderRight={1}
            borderRightColor="offwhite"
            {...props}
        >
            <Datepicker value={format(start, 'YYYY-MM-DD')} onSelect={onDateChange}>
                <Button variant="tertiary" height={32} width={32} p={0} ref={calendarRef}>
                    <Calendar />
                </Button>
            </Datepicker>
            <Button variant="tertiary" height={32} py={0} onClick={() => onDateChange(new Date())}>
                {Translated('today')}
            </Button>
        </HStack>
    )
}

export default Dates
