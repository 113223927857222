import React from 'react'
import startOfDay from 'date-fns/start_of_day'
import addDays from 'date-fns/add_days'
import format from 'date-fns/format'
import qs from 'qs'
import flatMap from 'lodash.flatmap'

import { useHistory } from 'react-router-dom'
import { Container, HStack } from '../components/Layout'

import { Scissor, Erase, Pen, Undo, Triangle, Logo, Menu as MenuIcon, User, Back, Forward } from '../components/Icon'
import PillGroup, { Pill } from '../components/PillGroup'
import Button from '../components/Button'
import Badge from '../components/Badge'
import Text from '../components/Text'
import Box, { BoxProps } from '../components/Box'
import Teams from '../components/Teams'
import Link from '../components/Link'
import Machines from '../components/Machines'
import { timeline } from '../api'
import { useAsyncAbortable, useAsyncCallback } from '../hooks/useAsync'
import Loader, { LoaderComponent } from '../components/Loader'
import Dates, { TimelineDatepicker, DatesWithDaysOnly } from '../components/Dates'
import { getSteppedValue } from '../components/Draggable'
import { parse, isEqual, subDays } from 'date-fns'
import useDebounce from '../hooks/useDebounce'
import { useWindowSize } from '../hooks/useWindowSize'
import { Menu, MenuButton, MenuList, MenuItem } from '../components/Menu'
import { MenuLink } from '@reach/menu-button'
import { Route } from 'react-router-dom'
import { CreateWorkOrderModal, UpdateWorkOrderModal } from '../modals/WorkOrderModal'
import {
    CreateWorkperiodModalContainer,
    UpdateWorkperiodModal,
    UpdateWorkperiodModalContainer
} from '../modals/WorkperiodModal'
import useAuth from '../hooks/useAuth'
import { UserContext } from '../App'
import { HeaderUser } from '../components/Header'
import { OperatorWorkOrderModal } from '../modals/OperatorWorkOrderModal'
import ErrorBoundary from 'react-error-boundary'
import ErrorFallback from '../components/ErrorFallback'

interface HeaderProps extends BoxProps {
    mode: 'cut' | 'edit' | 'erase'
    warningsCount: number
    setMode: React.Dispatch<React.SetStateAction<'edit' | 'cut' | 'erase'>>
    loading?: boolean
}

const Header: React.FC<HeaderProps> = ({ warningsCount, mode, loading = false, setMode, ...props }) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role

    const isOperator = role === 'operator'
    const isAdmin = role === 'admin'

    const publish = useAsyncCallback(timeline.publishChanges)

    return (
        <Box display="grid" gridTemplateColumns="214px 2fr 1fr" gridGap={2} p={3} as={Container} {...props}>
            <HStack
                width={214}
                height={32}
                justifyContent="space-between"
                borderRight={1}
                borderRightColor="offwhite"
                alignItems="center"
            >
                <Link state={{}} to="/timeline">
                    <Logo />
                </Link>

                {isAdmin && (
                    <Menu>
                        <MenuButton css={{ bg: 'white' }}>
                            <HStack alignItems="center" spacing={2} height={32} pr={2}>
                                <Text fontSize="sm" mb={0} fontWeight="semibold">
                                    MENU
                                </Text>
                                <MenuIcon />
                            </HStack>
                        </MenuButton>
                        <MenuList>
                            <MenuLink
                                href="/#/admin"
                                css={{
                                    textDecoration: 'none',
                                    color: 'black',
                                    display: 'block'
                                }}
                            >
                                System admin
                            </MenuLink>
                        </MenuList>
                    </Menu>
                )}
            </HStack>
            <HStack alignItems="flex-start">
                {!isOperator && (
                    <>
                        <PillGroup bg="offwhite" alignSelf="flex-start" p={1}>
                            <Pill onClick={() => setMode('edit')} isSelected={mode === 'edit'}>
                                <Pen />
                            </Pill>
                            <Pill onClick={() => setMode('cut')} isSelected={mode === 'cut'}>
                                <Scissor />
                            </Pill>
                            <Pill onClick={() => setMode('erase')} isSelected={mode === 'erase'}>
                                <Erase />
                            </Pill>
                        </PillGroup>
                        <Box
                            height={32}
                            width={32}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            p={0}
                            overflow="visible"
                        >
                            <Triangle />
                            {warningsCount > 0 ? <Badge>{warningsCount}</Badge> : null}
                        </Box>
                        <Button
                            onClick={publish.execute}
                            loading={publish.loading}
                            variant="tertiary"
                            height={32}
                            px={3}
                            py={1}
                        >
                            Publish changes
                        </Button>
                        {loading && (
                            <Box display="flex" height={32} alignItems="center">
                                <LoaderComponent scale={16} />
                            </Box>
                        )}
                    </>
                )}
            </HStack>
            <HeaderUser />
        </Box>
    )
}

const TimelineButton: React.FC<BoxProps> = ({ children, ...props }) => (
    <Box
        as={Button}
        px={0}
        py={0}
        css={{ '&:hover': { bg: 'transparent' } }}
        bg="transparent"
        border="none"
        height={150}
        width={150}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        position="fixed"
        top="calc(50% - 75px)"
        zIndex={3}
        {...props}
    >
        <Box
            height={150}
            width={150}
            position="absolute"
            left={0}
            top={0}
            borderRadius="100%"
            bg="brownish-grey"
            opacity={0.2}
        ></Box>
        {children}
    </Box>
)

interface TimelinePageProps {}

export const TimelineContext = React.createContext<any | null>(null)

const TimelinePage: React.FunctionComponent<TimelinePageProps> = ({ children }) => {
    const history = useHistory()
    const params = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const size = useWindowSize()
    const [width] = useDebounce(size, 1000)
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const user_access = user && user.entity.operator_access

    const getDayCount = () => getSteppedValue((window.innerWidth - 260) / 40, 1)
    const [mode, setMode] = React.useState<'edit' | 'cut' | 'erase'>('edit')
    const [zoomLevel, setZoomLevel] = React.useState<1 | 2>(1)

    const _start = startOfDay(params.date && !/workorder/.test(params.date) ? parse(params.date) : (subDays(new Date(), 5)))
    const dayCount = getDayCount()
    const _end = addDays(_start, dayCount)
    const [[start, end], setRange] = React.useState<[Date, Date]>([_start, _end])

    const onDateChange = (d: Date) => {
        history.push({ search: 'date=' + format(d, 'YYYY-MM-DD') })
    }

    const machinesData = useAsyncAbortable(abortSignal => timeline.getMachines(start, end, abortSignal), [start, end], {
        setLoading: state => ({ ...state, loading: true })
    })

    const teamsData = useAsyncAbortable(abortSignal => timeline.getTeams(start, end, abortSignal), [start, end], {
        setLoading: state => ({ ...state, loading: true })
    })

    React.useEffect(() => {
        document.body.className = mode
    }, [mode])

    React.useEffect(() => {
        const dayCount = getDayCount()
        const newEnd = addDays(start, dayCount)
        if (!isEqual(end, newEnd)) {
            setRange([start, newEnd])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    React.useEffect(() => {
        if (params.date && !/workorder/.test(params.date)) {
            const _start = startOfDay(parse(params.date))
            const dayCount = getDayCount()
            const _end = addDays(_start, dayCount)

            if (!isEqual(_start, start)) {
                setRange([_start, _end])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.date])

    const onWorkorderChange = () => {
        teamsData.execute()
    }

    const onWorkorderDelete = (w: WorkOrder) => {
        if (machinesData.result) {
            let newMachines = [...machinesData.result.machines]
            let machine = newMachines.find(x => x.id === w.machine_id)

            if (machine) {
                machine.orders = machine.orders.filter(x => x.id !== w.id)
                machinesData.setResult({ ...machinesData.result, machines: newMachines })
            }
            teamsData.execute()
        }
    }

    const onWorkorderCreated = (workorder: WorkOrder) => {
        if (machinesData.result) {
            let newMachines = [...machinesData.result.machines]
            let machine = newMachines.find(x => x.id === workorder.machine_id)

            if (machine) {
                machine.orders.push({
                    ...workorder,
                    startdate: new Date(workorder.startdate),
                    enddate: new Date(workorder.enddate)
                } as Order)
                machinesData.setResult({ ...machinesData.result, machines: newMachines })
                onWorkorderChange()
            }
        }
    }
    const onWorkorderUpdated = (workorder: WorkOrder) => {
        if (machinesData.result) {
            let newMachines = [...machinesData.result.machines]
            let machine = newMachines.find(x => x.id === workorder.machine_id)
            if (machine) {
                const index = machine.orders.findIndex(x => x.id === workorder.id)
                machine.orders[index] = {
                    ...workorder,
                    startdate: new Date(workorder.startdate),
                    enddate: new Date(workorder.enddate)
                } as Order
                machinesData.setResult({ ...machinesData.result, machines: newMachines })
                onWorkorderChange()
            }
        }
    }

    const onWorkperiodCreated = (workperiod: WorkperiodSingle) => {
        teamsData.execute()
        machinesData.execute()
    }

    const onWorkperiodUpdated = (workperiod: WorkperiodSingle) => {
        teamsData.execute()
        machinesData.execute()
    }

    const onWorkperiodDelete = (w: WorkperiodSingle) => {
        teamsData.execute()
        machinesData.execute()
    }

    const warningCount = machinesData.result
        ? machinesData.result.machines.reduce((sum, machine) => {
              const days = flatMap(machine.orders, x => x.days)
              // @ts-ignore
              const warnings = flatMap(days, x => x.warning).filter(Boolean)
              return sum + warnings.length
          }, 0)
        : 0

    const isOperator = role === 'operator'
    const isNotOperator = role !== 'operator'

    const shouldShowMachines = isNotOperator || (isOperator && /Workorder/.test(user_access || ''))
    const shouldShowTeams = isNotOperator || /Workperiod/.test(user_access || '')

    return (
        <TimelineContext.Provider
            value={{ date: start, fetchTeams: teamsData.execute, fetchMachines: machinesData.execute }}
        >
            <Box bg="offwhite" minHeight="100vh">
                <Box
                    bg="white"
                    position="sticky"
                    top={0}
                    zIndex={100}
                    mb="5px"
                    css={{
                        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)'
                    }}
                >
                    <Header
                        warningsCount={warningCount}
                        mode={mode}
                        loading={machinesData.loading || teamsData.loading}
                        setMode={setMode}
                    />
                    <HStack
                        spacing={0}
                        css={{
                            overflowX: 'hidden'
                        }}
                    >
                        <TimelineDatepicker width={230} start={start} end={end} onDateChange={onDateChange} />
                        <Dates dateinfo={machinesData.result && machinesData.result.dateinfo} start={start} end={end} />
                    </HStack>
                </Box>

                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {machinesData.loading && !machinesData.result && (
                        <Box height={200} width="100%">
                            <Loader />
                        </Box>
                    )}

                    {machinesData.error && (
                        <Box height={200} width="100%" display="flex" justifyContent="center" alignItems="center">
                            <Text color="red">{machinesData.error.message}</Text>
                        </Box>
                    )}

                    {machinesData.result && shouldShowMachines && (
                        <Machines
                            zoomLevel={zoomLevel}
                            start={start}
                            onMachinesUpdate={onWorkorderChange}
                            end={end}
                            mode={mode}
                            machines={machinesData.result.machines}
                            setMachines={machines => {
                                if (machinesData.result) {
                                    machinesData.setResult({
                                        dateinfo: machinesData.result.dateinfo,
                                        machines
                                    })
                                }
                            }}
                            onCut={() => setMode('edit')}
                        />
                    )}

                    {shouldShowMachines && (
                        <Box
                            bg="white"
                            pl={230}
                            mb="5px"
                            css={{
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)',
                                overflowX: 'hidden'
                            }}
                        >
                            <DatesWithDaysOnly start={start} end={end} />
                        </Box>
                    )}

                    {teamsData.loading && !teamsData.result && (
                        <Box height={200} width="100%">
                            <Loader />
                        </Box>
                    )}

                    {teamsData.error && (
                        <Box height={200} width="100%" display="flex" justifyContent="center" alignItems="center">
                            <Text color="red">{teamsData.error.message}</Text>
                        </Box>
                    )}

                    <TimelineButton
                        left={-100}
                        onClick={() => {
                            const _start = subDays(start, getDayCount() / 2)
                            onDateChange(_start)
                        }}
                    >
                        <Back mr={3} />
                    </TimelineButton>
                    <TimelineButton
                        onClick={() => {
                            const _start = addDays(start, getDayCount() / 2)
                            onDateChange(_start)
                        }}
                        right={-100}
                        justifyContent="flex-start"
                    >
                        <Forward ml={3} />
                    </TimelineButton>

                    {teamsData.result && shouldShowTeams && (
                        <Teams
                            zoomLevel={zoomLevel}
                            start={start}
                            onUpdate={() => {
                                teamsData.execute()
                                setMode('edit')
                            }}
                            end={end}
                            mode={mode}
                            teams={teamsData.result}
                            setTeams={teamsData.setResult}
                            onCut={() => setMode('edit')}
                        />
                    )}
                    <Route
                        render={() => {
                            return <OperatorWorkOrderModal />
                        }}
                        exact
                        path="/timeline/operator-workorder/:id"
                    />
                    <Route
                        render={() => {
                            return <CreateWorkOrderModal onDelete={() => {}} onUpdate={onWorkorderCreated} />
                        }}
                        exact
                        path="/timeline/workorder/"
                    />
                    <Route
                        render={() => {
                            return <UpdateWorkOrderModal onDelete={onWorkorderDelete} onUpdate={onWorkorderUpdated} />
                        }}
                        path="/timeline/workorder/:id"
                    />
                    <Route
                        render={() => {
                            return (
                                <CreateWorkperiodModalContainer
                                    onDelete={onWorkperiodDelete}
                                    onUpdate={onWorkperiodCreated}
                                />
                            )
                        }}
                        exact
                        path="/timeline/workperiod/"
                    />
                    <Route
                        render={() => {
                            return (
                                <UpdateWorkperiodModalContainer
                                    onDelete={onWorkperiodDelete}
                                    onUpdate={onWorkperiodUpdated}
                                />
                            )
                        }}
                        path="/timeline/workperiod/:id"
                    />
                </ErrorBoundary>
            </Box>
        </TimelineContext.Provider>
    )
}

export default TimelinePage
