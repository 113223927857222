import React from 'react'
import addDays from 'date-fns/add_days'

import { HStack, VStack, StackProps } from './Layout'
import { TeamsTimeline } from './Timeline'
import Box from './Box'
import Text from './Text'
import { ArrowDown, Visibility, Sort } from './Icon'
import { Touchable } from './Button'
import { useAsyncCallback } from '../hooks/useAsync'
import { timeline } from '../api'
import { isSameDay, getHours, setHours, differenceInHours, addHours, differenceInDays } from 'date-fns'
import useLocalStorage from '../hooks/useLocalStorage'
import { Translated } from '../context/LocaleContext'

interface TeamsProps extends StackProps {
    zoomLevel: 1 | 2
    mode: 'cut' | 'edit' | 'erase'
    start: Date
    end: Date
    teams: Team[]
    setTeams: (state: Team[]) => void
    onUpdate: () => void
    onCut: () => void
}

const Teams: React.FC<TeamsProps> = ({ zoomLevel, mode, onCut, teams, setTeams, start, end, onUpdate, ...props }) => {
    const moveWorkperiod = useAsyncCallback((w: Workperiod) => timeline.moveWorkperiod(w))
    const [showTeamsTimeline, setShowTeamsTimeline] = useLocalStorage('ui-show-teams-timeline', true)

    const [teamsClosed, setTeamsClosed] = useLocalStorage<number[]>('teams-state', [])

    const onPositionChange = React.useCallback(
        (start: Date, teamIndex: number, workerIndex: number, workperiodIndex: number, dayCount: number) => {
            const newTeams = [...teams]
            const team = newTeams[teamIndex]
            const worker = team.people[workerIndex]
            const workPeriod = worker.workperiods[workperiodIndex]

            if (workPeriod.id === -1) {
                return
            }

            const leavingHomeDiff =
                workPeriod.use_travel_time && workPeriod.leaving_home
                    ? differenceInDays(workPeriod.leaving_home, workPeriod.startdate)
                    : 0

            const returningHomeDiff =
                workPeriod.use_travel_time && workPeriod.returning_home
                    ? differenceInDays(workPeriod.returning_home, workPeriod.enddate)
                    : 0

            const startdate = start
            const enddate = addDays(start, dayCount)

            if (isSameDay(startdate, workPeriod.startdate) && isSameDay(enddate, workPeriod.enddate)) {
                return
            }

            workPeriod.startdate = startdate
            workPeriod.enddate = enddate

            if (workPeriod.use_travel_time) {
                workPeriod.leaving_home = addDays(workPeriod.startdate, leavingHomeDiff)
                workPeriod.returning_home = addDays(workPeriod.enddate, returningHomeDiff)
            }

            moveWorkperiod.execute(workPeriod).then(updatedWorkperiod => {
                worker.workperiods[workperiodIndex] = {
                    ...workPeriod,
                    startdate: updatedWorkperiod.startdate,
                    enddate: updatedWorkperiod.enddate,
                    leaving_home: updatedWorkperiod.leaving_home,
                    returning_home: updatedWorkperiod.returning_home
                }
                setTeams(newTeams)
            })
        },
        [setTeams, teams, moveWorkperiod]
    )

    const onWorkperiodCut = React.useCallback(
        (dayIndex: number, teamIndex: number, personIndex: number, workperiodIndex: number) => {
            const newTeams = [...teams]
            const team = teams[teamIndex]
            const person = team.people[personIndex]
            const workperiod = person.workperiods[workperiodIndex]

            const date = addDays(workperiod.startdate, dayIndex)

            timeline.cutWorkperiod(workperiod, date).then(([newWorkperiod, copy]) => {
                person.workperiods[workperiodIndex] = newWorkperiod
                person.workperiods.push(copy)

                setTeams(newTeams)
                onCut()
            })
        },
        [teams, setTeams, onCut]
    )

    const onShiftPeriodRemoved = (
        teamIndex: number,
        workerIndex: number,
        workperiodIndex: number,
        x: Shiftperiod,
        index: number
    ) => {
        const newTeams = [...teams]
        const team = teams[teamIndex]
        const person = team.people[workerIndex]
        const workperiod = person.workperiods[workperiodIndex]

        timeline.getWorkperiod(workperiod.id).then(orig => {
            orig.shiftperiods = orig.shiftperiods.filter(y => {
                return y.machine_shift_id !== x.machine_shift_id
            })
            timeline.removeShiftperiod(orig).then(res => {
                workperiod.shiftperiods = res.shiftperiods
                setTeams(newTeams)
                onCut()
            })
        })
    }

    const onShiftRemoved = (
        teamIndex: number,
        workerIndex: number,
        workperiodIndex: number,
        date: Date,
        index: number
    ) => {
        const newTeams = [...teams]
        const team = teams[teamIndex]
        const person = team.people[workerIndex]
        const workperiod = person.workperiods[workperiodIndex]

        const shiftperiod = workperiod.shiftperiods[index]
        shiftperiod.shifts = shiftperiod.shifts.filter(y => {
            return !isSameDay(date, y.date)
        })

        timeline.getWorkperiod(workperiod.id).then(orig => {
            orig.shiftperiods = orig.shiftperiods.map((x: Shiftperiod) => {
                x.shifts = x.shifts.filter(y => {
                    return !isSameDay(date, y.date)
                })
                return x
            })
            timeline.removeShiftperiod(orig).then(res => {
                workperiod.shiftperiods = res.shiftperiods

                setTeams(newTeams)
                onCut()
            })
        })
    }

    return (
        <VStack alignSelf="flex-start" {...props}>
            <HStack bg="offwhite" spacing={0}>
                <VStack spacing={0} width={230} px={3} display="flex" css={{ flexShrink: 0, flexGrow: 0 }} pb={11}>
                    <HStack spacing={0} justifyContent="space-between">
                        <HStack spacing={2} ml={40} height={24} alignItems="center">
                            <Touchable onClick={() => setShowTeamsTimeline(!showTeamsTimeline)}>
                                <Visibility color={showTeamsTimeline ? 'black' : 'brownish-grey'} />
                            </Touchable>
                            <Sort />
                        </HStack>
                        <Box height={24} mb={1} display="flex" alignItems="center">
                            <Text
                                m={0}
                                fontWeight="semibold"
                                fontSize="xs"
                                lineHeight="normal"
                                color="brownish-grey"
                                css={{ textTransform: 'uppercase' }}
                            >
                                {Translated('operators')}
                            </Text>
                        </Box>
                    </HStack>
                    {showTeamsTimeline &&
                        teams.map((team, teamIndex) => {
                            const hidden = teamsClosed.indexOf(team.id) !== -1

                            return (
                                <Box
                                    key={'team - ' + teamIndex}
                                    display="flex"
                                    flexDirection="column"
                                    textAlign="right"
                                    width="100%"
                                >
                                    <HStack
                                        as={Touchable}
                                        onClick={() => {
                                            const newTeamsClosed = [...teamsClosed]
                                            if (hidden) {
                                                setTeamsClosed(newTeamsClosed.filter(x => x !== team.id))
                                            } else {
                                                setTeamsClosed([...newTeamsClosed, team.id])
                                            }
                                        }}
                                        p={0}
                                        height={24}
                                        mb="2px"
                                        spacing={1}
                                        alignSelf="flex-end"
                                    >
                                        <Text
                                            m={0}
                                            fontWeight="bold"
                                            color="brownish-grey"
                                            css={{
                                                textTransform: 'uppercase',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {team.name}
                                        </Text>
                                        <ArrowDown
                                            color="brownish-grey"
                                            css={{
                                                transform: !hidden ? 'rotate(-180deg)' : 'none'
                                            }}
                                        />
                                    </HStack>
                                    {!hidden
                                        ? team.people.map(p => (
                                              <Box height={24} mb="2px" key={p.id}>
                                                  <Text
                                                      fontWeight="semibold"
                                                      fontSize="sm"
                                                      color="black"
                                                      lineHeight="1.52"
                                                      m={0}
                                                      css={{
                                                          whiteSpace: 'nowrap',
                                                          textOverflow: 'ellipsis'
                                                      }}
                                                  >
                                                      {`${p.name} ${p.shiftinfo.number || ''}`}
                                                  </Text>
                                              </Box>
                                          ))
                                        : null}
                                </Box>
                            )
                        })}
                </VStack>
                {showTeamsTimeline && (
                    <TeamsTimeline
                        zoomLevel={zoomLevel}
                        mode={mode}
                        start={start}
                        end={end}
                        data={teams}
                        teamsClosed={teamsClosed}
                        onPositionChange={onPositionChange}
                        onShiftPeriodRemoved={onShiftPeriodRemoved}
                        onShiftRemoved={onShiftRemoved}
                        onWorkperiodRemoved={() => onUpdate()}
                        onWorkperiodCut={onWorkperiodCut}
                    ></TeamsTimeline>
                )}
            </HStack>
        </VStack>
    )
}

export default Teams
