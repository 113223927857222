import React from 'react'
import Box, { BoxProps } from './Box'

type Loader = {
    strokeSize?: string | number
    scale?: string | number
} & BoxProps

export const LoaderComponent: React.FunctionComponent<Loader> = ({
    scale = 24,
    strokeSize = '2px',
    color = 'black',
    ...props
}) => (
    <Box
        position="relative"
        width={scale}
        height={scale}
        color={color}
        css={{
            pointerEvents: 'none',
            '&:after': {
                animation: `
                    rotate 0.5s infinite linear
                `,
                border: `${strokeSize} solid currentColor`,
                borderRadius: '50%',
                borderRightColor: 'transparent',
                borderTopColor: 'transparent',
                content: '""',
                display: 'block',
                height: '100%',
                left: '50%',
                marginLeft: '-50%',
                marginTop: '-50%',
                position: 'absolute',
                top: '50%',
                width: '100%',
                zIndex: 1
            }
        }}
        {...props}
    />
)

const Loader: React.FunctionComponent<Loader> = ({ bg, ...props }) => (
    <Box
        width="100%"
        height="100%"
        position="absolute"
        left={0}
        top={0}
        display="flex"
        bg={bg}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
    >
        <LoaderComponent {...props} />
    </Box>
)

export default Loader
