import React, { ChangeEvent } from 'react'
import Box from '../components/Box'
import { useAsyncAbortable, useAsyncCallback, UseAsyncReturn } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import Button from '../components/Button'
import { useParams, useHistory } from 'react-router-dom'
import Input from '../components/Input'
import Text from '../components/Text'
import { VStack, HStack, Spacer } from '../components/Layout'
import { AdminUser, AdminTeam, AdminLegalEntity } from '../types/admin'
import { Select } from '../components/Select'

const roles = [
    { id: 1, label: 'Admin' },
    { id: 2, label: 'Staff planner' },
    { id: 3, label: 'Project manager' },
    { id: 4, label: 'Operator' }
]

const UserForm: React.FC<{
    user: AdminUser
    teams: AdminTeam[]
    legalEntities: AdminLegalEntity[]
    onChange: (e: any) => void
    onDelete?: (e: any) => void
    onSubmit: (e: any, password: string, repeatPassword: string) => void
    save: UseAsyncReturn<any, any>
    remove?: UseAsyncReturn<any, any>
}> = ({ user, onChange, onSubmit, onDelete, save, remove, teams, legalEntities }) => {
    const error = save.error || (remove && remove.error)

    const [password, setPassword] = React.useState('')
    const [repeatPassword, setRepeatPassword] = React.useState('')

    const onLocalSubmit = (e: any) => {
        e.persist()
        onSubmit(e, password, repeatPassword)
    }

    const onTextChange = (e: any) => {
        onChange({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const onShiftTypeChange = (e: any) => {
        const id = Number(e.target.value)

        if (!Number.isNaN(id)) {
            onChange({
                ...user,
                teams: user.teams.map(x => {
                    x.shiftperiod_id = id
                    return x
                })
            })
        } else {
            onChange({
                ...user,
                teams: user.teams.map(x => {
                    x.shiftperiod_id = null
                    return x
                })
            })
        }
    }

    const legalEntitiesOptions = legalEntities.map(x => ({ label: x.name, value: x.id }))
    const teamsOptions = teams.map(x => ({ label: x.name, value: x.id }))

    const currentShiftType = user.teams.map(x => x.shiftperiod_id)

    return (
        <VStack as="form" spacing={3} width={661} mx="auto" onSubmit={onLocalSubmit}>
            <HStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="name" fontWeight="semibold">
                        Name
                    </Text>
                    <Input my={0} value={user.name} name="name" onChange={onTextChange} />
                </VStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="user_email" fontWeight="semibold">
                        Email
                    </Text>
                    <Input
                        my={0}
                        value={user.email}
                        name="user_email"
                        onChange={(e: any) => {
                            onChange({ ...user, email: e.target.value })
                        }}
                    />
                </VStack>
            </HStack>
            <HStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="phone" fontWeight="semibold">
                        Phone
                    </Text>
                    <Input my={0} value={user.phone} name="phone" onChange={onTextChange} />
                </VStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="assigned_to" fontWeight="semibold">
                        Allowed legal entities
                    </Text>

                    <Select
                        isMulti={true}
                        options={legalEntitiesOptions}
                        value={user.allowedentity.map(x => ({ label: x.name, value: x.id }))}
                        onChange={value => {
                            if (Array.isArray(value)) {
                                onChange({
                                    ...user,
                                    allowedentity: legalEntities.filter(y => value.find(x => x.value === y.id))
                                })
                            } else if (value === null) {
                                onChange({
                                    ...user,
                                    allowedentity: []
                                })
                            }
                        }}
                    ></Select>
                </VStack>
            </HStack>
            <HStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="role" fontWeight="semibold">
                        Role
                    </Text>
                    <Input.NativeSelect value={user.role} my={0} name="role" onChange={onTextChange}>
                        {roles.map(x => (
                            <option value={x.id}>{x.label}</option>
                        ))}
                    </Input.NativeSelect>
                </VStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="assigned_to" fontWeight="semibold">
                        Assigned to
                    </Text>

                    <Select
                        isMulti={true}
                        options={teamsOptions}
                        value={user.teams.map(x => ({ label: x.name, value: x.id }))}
                        onChange={value => {
                            if (Array.isArray(value)) {
                                onChange({
                                    ...user,
                                    teams: teams.filter(y => value.find(x => x.value === y.id))
                                })
                            } else if (value === null) {
                                onChange({
                                    ...user,
                                    teams: []
                                })
                            }
                        }}
                    ></Select>
                </VStack>
            </HStack>
            <HStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="status" fontWeight="semibold">
                        Status
                    </Text>
                    <Input.NativeSelect value={user.status} my={0} name="status" onChange={onTextChange}>
                        <option value={'active'}>Active</option>
                        <option value={'inactive'}>Inactive</option>
                    </Input.NativeSelect>
                </VStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="shift_type" fontWeight="semibold">
                        Shift type
                    </Text>
                    <Input.NativeSelect
                        value={currentShiftType && currentShiftType[0]}
                        my={0}
                        name="shift_type"
                        onChange={onShiftTypeChange}
                    >
                        <option>None</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                    </Input.NativeSelect>
                </VStack>
            </HStack>
            <HStack>
                <VStack spacing={1} flexBasis="50%">
                    <Input.Checkbox
                        checked={user.email_notify}
                        onChange={(e: any) => {
                            onChange({
                                ...user,
                                email_notify: e.target.checked
                            })
                        }}
                    >
                        Notify changes via email
                    </Input.Checkbox>
                </VStack>
                <VStack spacing={1} flexBasis="50%"></VStack>
            </HStack>
            <HStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="user_password" fontWeight="semibold">
                        Password
                    </Text>
                    <Input
                        my={0}
                        type="password"
                        name="user_password"
                        onChange={(e: any) => setPassword(e.target.value)}
                    />
                </VStack>
                <VStack spacing={1} flexBasis="50%">
                    <Text as="label" htmlFor="user_password_repeat" fontWeight="semibold">
                        Repeat password
                    </Text>
                    <Input
                        my={0}
                        type="password"
                        name="user_password_repeat"
                        onChange={(e: any) => setRepeatPassword(e.target.value)}
                    />
                </VStack>
            </HStack>
            {error && (
                <Text color="red" my={0}>
                    {error.message}
                </Text>
            )}
            <HStack>
                <Button type="submit" loading={save.loading}>
                    Save
                </Button>
                <Spacer />
                {remove && onDelete && (
                    <Button variant="secondary" onClick={onDelete} loading={remove.loading}>
                        Delete user
                    </Button>
                )}
            </HStack>
        </VStack>
    )
}

export const CreateUserPage: React.FunctionComponent = ({ children, ...props }) => {
    const history = useHistory()
    const teamsData = useAsyncAbortable(abortSignal => Admin.getTeams(abortSignal), [])
    const legalEntitiesData = useAsyncAbortable(abortSignal => Admin.getLegalEntities(abortSignal), [])
    const save = useAsyncCallback(Admin.createUser)
    const savePassword = useAsyncCallback(Admin.savePassword)

    const [user, setUser] = React.useState<Partial<AdminUser>>({
        id: -1,
        name: '',
        teams: [],
        allowedentity: [],
        email: '',
        email_notify: false,
        status: 'active',
        phone: '',
        role: 4,
        deleted: false
    })

    const teams = teamsData.result
    const legalEntities = legalEntitiesData.result

    const loading = teamsData.loading || legalEntitiesData.loading
    const error = teamsData.error || legalEntitiesData.error

    const onChange = (payload: typeof user) => {
        if (user) {
            setUser(payload)
        }
    }

    const onSubmit = async (e: any, password: string, repeatPassword: string) => {
        e.preventDefault()
        if (password !== '') {
            if (password === repeatPassword) {
            } else {
                save.setError(new Error('Passwords need to match!'))
                return
            }
        }

        if (user) {
            save.execute(user).then(async user => {
                if (password !== '') {
                    await savePassword.execute(user, password)
                }
                history.replace('/admin/users')
            })
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {error && (
                <Box height={300}>
                    <Text color="red">{error.message}}</Text>
                </Box>
            )}
            {user && teams && legalEntities && (
                <UserForm
                    user={user as AdminUser}
                    teams={teams}
                    legalEntities={legalEntities}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    save={save}
                />
            )}
        </Box>
    )
}

const EditUserPage: React.FunctionComponent = ({ children, ...props }) => {
    const params = useParams()
    const history = useHistory()
    const id = params.id || '1'
    const data = useAsyncAbortable(abortSignal => Admin.getUser(Number(id), abortSignal), [])
    const teamsData = useAsyncAbortable(abortSignal => Admin.getTeams(abortSignal), [])
    const legalEntitiesData = useAsyncAbortable(abortSignal => Admin.getLegalEntities(abortSignal), [])
    const save = useAsyncCallback(Admin.updateUser)
    const savePassword = useAsyncCallback(Admin.savePassword)
    const remove = useAsyncCallback(Admin.removeUser)

    const user = data.result
    const teams = teamsData.result
    const legalEntities = legalEntitiesData.result

    const loading = teamsData.loading || data.loading || legalEntitiesData.loading
    const error = teamsData.error || data.error || legalEntitiesData.error

    const onChange = (payload: AdminUser) => {
        if (user) {
            // @ts-ignore
            data.setResult(payload)
        }
    }

    const onDelete = (e: any) => {
        e.preventDefault()
        if (user) {
            remove.execute(user).then(() => {
                history.replace('/admin/users')
            })
        }
    }

    const onSubmit = async (e: any, password: string, repeatPassword: string) => {
        e.preventDefault()
        if (password !== '') {
            if (password === repeatPassword) {
            } else {
                save.setError(new Error('Passwords need to match!'))
                return
            }
        }

        if (user) {
            save.execute(user).then(async user => {
                if (password !== '') {
                    await savePassword.execute(user, password)
                }
                history.replace('/admin/users')
            })
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {error && (
                <Box height={300}>
                    <Text color="red">{error.message}}</Text>
                </Box>
            )}
            {user && teams && legalEntities && (
                <UserForm
                    user={user}
                    teams={teams}
                    legalEntities={legalEntities}
                    onChange={onChange}
                    onDelete={onDelete}
                    onSubmit={onSubmit}
                    save={save}
                    remove={remove}
                />
            )}
        </Box>
    )
}

export default EditUserPage
