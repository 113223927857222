import React from 'react'
import Box, { BoxProps } from '../components/Box'
import { useAsyncAbortable, useAsyncCallback } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import { TableRow, TableHeader, TableColumn } from '../components/Table'
import { Touchable } from '../components/Button'
import { BigAdd } from '../components/Icon'
import { AdminAbsence } from '../types/admin'
import { ControlledInput } from './TeamsPage'
import Confirm from '../components/Confirm'

interface PageProps {}

interface ActionPayload {
    actions: {
        description: string
        action: string
    }[]
    absence: AdminAbsence
    onOk: (payload: ActionPayload) => void
    onCancel: () => void
}

const AbsenceRow: React.FC<{
    absence: AdminAbsence
    onUpdate: (a: AdminAbsence) => void
    onRemove: (a: AdminAbsence) => void
}> = ({ absence, onUpdate, onRemove }) => {
    const [actionPayload, setActionPayload] = React.useState<ActionPayload | null>(null)
    const remove = useAsyncCallback(Admin.removeAbsence)
    const update = useAsyncCallback(Admin.updateAbsence)
    const create = useAsyncCallback(Admin.createAbsence)

    const onTeamChange = (a: AdminAbsence) => {
        if (a.id === -1) {
            const { id, ...payload } = a
            create.execute(payload as AdminAbsence).then(updatedA => {
                onUpdate(updatedA)
            })
        } else {
            update.execute(a).then(updatedA => {
                onUpdate(updatedA)
            })
        }
    }

    const onLocalRemove = (action?: { description: string; action: string }) => {
        remove
            .execute(absence, action)
            .then(() => {
                onRemove(absence)
            })
            .catch(e => {
                if (e.actions) {
                    setActionPayload({
                        actions: e.actions,
                        absence: absence,
                        onOk: (actionPayload: ActionPayload) => {
                            if (actionPayload) {
                                onLocalRemove(actionPayload.actions[0])
                            }
                        },
                        onCancel: () => {
                            setActionPayload(null)
                        }
                    })
                }
            })
    }

    return (
        <TableRow>
            <TableColumn>
                <ControlledInput
                    disabled={update.loading || create.loading}
                    mb={0}
                    value={absence.description}
                    onChange={(value: string) => {
                        onTeamChange({ ...absence, description: value })
                    }}
                />
            </TableColumn>
            <TableColumn textAlign="right">
                <Touchable
                    loading={remove.loading}
                    css={{ textTransform: 'uppercase' }}
                    fontWeight="semibold"
                    onClick={() => {
                        if (absence.id !== -1) {
                            onLocalRemove()
                        } else {
                            onRemove(absence)
                        }
                    }}
                >
                    {remove.loading ? '' : 'Delete'}
                </Touchable>
                {actionPayload && (
                    <Confirm
                        message={actionPayload.actions[0].description}
                        onOk={() => actionPayload.onOk(actionPayload)}
                        onCancel={actionPayload.onCancel}
                    />
                )}
            </TableColumn>
        </TableRow>
    )
}

const AbsencePage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {
    const data = useAsyncAbortable(abortSignal => Admin.getAbsence(abortSignal), [])

    const onUpdate = (t: AdminAbsence, index: number) => {
        if (data.result) {
            const newAbsence = [...data.result]
            newAbsence[index] = t
            data.setResult(newAbsence)
        }
    }

    const onAdd = () => {
        if (data.result) {
            data.setResult([
                ...data.result,
                {
                    id: -1,
                    code: '',
                    description: ''
                }
            ])
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {data.result && (
                <Box as="table" mt={4} css={{ borderCollapse: 'collapse' }} width={661} mx="auto">
                    <tbody>
                        <TableRow>
                            <TableHeader>Reason for absence</TableHeader>
                            <TableHeader textAlign="right">
                                <Touchable onClick={onAdd}>
                                    <BigAdd></BigAdd>
                                </Touchable>
                            </TableHeader>
                        </TableRow>
                        {data.result.map((absence, teamIndex) => (
                            <AbsenceRow
                                onUpdate={(t: AdminAbsence) => onUpdate(t, teamIndex)}
                                onRemove={(t: AdminAbsence) => {
                                    if (data.result) {
                                        const newTeams = [...data.result]
                                        newTeams.splice(teamIndex, 1)
                                        data.setResult(newTeams)
                                    }
                                }}
                                absence={absence}
                                key={absence.id}
                            />
                        ))}
                    </tbody>
                </Box>
            )}
        </Box>
    )
}

export default AbsencePage
