import React, { FormEvent, ChangeEvent } from 'react'
import qs from 'qs'
import Box, { BoxProps } from '../components/Box'
import Input from '../components/Input'
import { HStack, VStack, Spacer } from '../components/Layout'
import Button from '../components/Button'
import Text from '../components/Text'
import Datepicker from '../components/Datepicker'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import { useAsync, useAsyncCallback, useAsyncAbortable } from '../hooks/useAsync'
import { timeline, Admin } from '../api'
import Loader from '../components/Loader'
import Modal from '../components/Modal'
import { useHistory, useParams } from 'react-router-dom'
import {
    addDays,
    setHours,
    getHours,
    isBefore,
    differenceInDays,
    differenceInHours,
    addHours,
    subHours
} from 'date-fns'
import { User } from '../types/users'
import { AdminAbsence } from '../types/admin'
import { Translated } from '../context/LocaleContext'
import Confirm from '../components/Confirm'

interface WorkperiodModalProps extends BoxProps {
    onUpdate: (workorder: WorkperiodSingle) => void
    onDelete: (workorder: WorkperiodSingle) => void
}

interface WorkperiodFormProps {
    useTravelTime: boolean
    absence: AdminAbsence[]
    workperiod: WorkperiodSingle
    onSubmit: (hints: { start: Date; end: Date }[]) => void
    onChange: (w: WorkperiodSingle) => void
    deleting: boolean
    loading: boolean
    error: any
    type: 'create' | 'update'
    goBack: () => void
    onDelete: () => void
}

const DateLabel: React.FC<BoxProps> = props => (
    <Text
        height={36}
        lineHeight="2.5"
        display="block"
        color="brownish-grey"
        fontSize="xs"
        fontWeight="semibold"
        width={100}
        {...props}
    ></Text>
)

const TimeSelect: React.FC<BoxProps> = props => (
    <Input.NativeSelect
        width="auto"
        height={36}
        p={0}
        px={2}
        m={0}
        fontSize="xs"
        css={{
            WebkitAppearance: 'none',
            background: 'offwhite'
        }}
        {...props}
    >
        {[...Array(24)].map((_, x) => {
            const time = (x < 10 ? `0${x}` : x) + ':00'

            return (
                <option key={time} value={x}>
                    {time}
                </option>
            )
        })}
    </Input.NativeSelect>
)

interface WorkTimeDatesProps {
    workperiod: WorkperiodSingle
    onChange: (w: WorkperiodSingle) => void
}

const WorkTimeDates: React.FC<WorkTimeDatesProps> = ({
    onChange,
    workperiod,
    workperiod: { leaving_home, returning_home, startdate, enddate, use_travel_time }
}) => {
    const onDatePickerChange = React.useCallback(
        (key: 'startdate' | 'enddate' | 'leaving_home' | 'returning_home', d: Date) => {
            const newWorkperiod = { ...workperiod }
            newWorkperiod[key] = d
            onChange(newWorkperiod)
        },
        [workperiod, onChange]
    )

    return (
        <HStack spacing={0} mt={3}>
            <VStack width={100} spacing={2} justifySelf="flex-start" alignSelf="flex-start" flex={0}>
                {use_travel_time && <DateLabel>{Translated('leaving_home')}</DateLabel>}
                <DateLabel>{Translated('start')}</DateLabel>
                <DateLabel>{Translated('end')}</DateLabel>
                {use_travel_time && <DateLabel>{Translated('returning_home')}</DateLabel>}
            </VStack>
            <VStack spacing={2}>
                {use_travel_time && leaving_home && (
                    <HStack>
                        <Datepicker
                            width={90}
                            value={format(leaving_home, 'YYYY-MM-DD')}
                            onSelect={(d: Date) => {
                                onDatePickerChange('leaving_home', d)
                            }}
                        />
                    </HStack>
                )}
                <HStack>
                    <Datepicker
                        width={90}
                        value={format(startdate, 'YYYY-MM-DD')}
                        onSelect={(d: Date) => {
                            onDatePickerChange('startdate', d)
                        }}
                    />
                </HStack>
                <HStack>
                    <Datepicker
                        width={90}
                        value={format(enddate, 'YYYY-MM-DD')}
                        onSelect={(d: Date) => {
                            onDatePickerChange('enddate', d)
                        }}
                    />
                </HStack>
                {use_travel_time && returning_home && (
                    <HStack>
                        <Datepicker
                            width={90}
                            value={format(returning_home, 'YYYY-MM-DD')}
                            onSelect={(d: Date) => {
                                onDatePickerChange('returning_home', d)
                            }}
                        />
                    </HStack>
                )}
            </VStack>
        </HStack>
    )
}

const VacationTimeDates: React.FC<WorkTimeDatesProps> = ({
    onChange,
    workperiod,
    workperiod: { startdate, enddate }
}) => {
    const onDatePickerChange = React.useCallback(
        (key: 'startdate' | 'enddate', d: Date) => {
            const newWorkperiod = { ...workperiod }
            const current = newWorkperiod[key]
            newWorkperiod[key] = setHours(d, getHours(current))
            onChange(newWorkperiod)
        },
        [workperiod, onChange]
    )

    const onTimeChange = React.useCallback(
        (key: 'startdate' | 'enddate', hours: number) => {
            const newWorkperiod = { ...workperiod }
            const current = newWorkperiod[key]
            newWorkperiod[key] = setHours(current, hours)
            onChange(newWorkperiod)
        },
        [workperiod, onChange]
    )

    return (
        <HStack spacing={0} mt={3}>
            <VStack width={100} spacing={2} justifySelf="flex-start" alignSelf="flex-start" flex={0}>
                <DateLabel>{Translated('start')}</DateLabel>
                <DateLabel>{Translated('end')}</DateLabel>
            </VStack>
            <VStack spacing={2}>
                <HStack>
                    <Datepicker
                        width={90}
                        value={format(startdate, 'YYYY-MM-DD')}
                        onSelect={(d: Date) => {
                            onDatePickerChange('startdate', d)
                        }}
                    />

                    <TimeSelect
                        value={getHours(startdate)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const hours = Number(e.target.value)
                            onTimeChange('startdate', hours)
                        }}
                    />
                </HStack>
                <HStack>
                    <Datepicker
                        width={90}
                        value={format(enddate, 'YYYY-MM-DD')}
                        onSelect={(d: Date) => {
                            onDatePickerChange('enddate', d)
                        }}
                    />

                    <TimeSelect
                        value={getHours(enddate)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const hours = Number(e.target.value)
                            onTimeChange('enddate', hours)
                        }}
                    />
                </HStack>
            </VStack>
        </HStack>
    )
}

const Label: React.FC<BoxProps> = ({ children, ...props }) => {
    return (
        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100} {...props}>
            {children}
        </Text>
    )
}

const WorkperiodForm: React.FC<WorkperiodFormProps> = ({
    useTravelTime,
    absence,
    type,
    deleting,
    loading,
    error,
    workperiod,
    goBack,
    onChange,
    onDelete,
    onSubmit
}) => {
    const [shouldRepeat, setShouldRepeat] = React.useState(false)

    const [repeat, setRepeat] = React.useState({
        days: 11,
        until: workperiod.leaving_home || workperiod.enddate
    })

    const onTypeChange = (e: any) => {
        e.persist()

        onChange({
            ...workperiod,
            periodtype: e.target.value
        })
    }

    const onAbsenceChange = (e: any) => {
        e.persist()

        onChange({
            ...workperiod,
            absence_reason_id: Number(e.target.value)
        })
    }

    const getHints = React.useCallback(() => {
        interface Hint {
            start: Date
            end: Date
            leaving_home?: Date
            returning_home?: Date
        }

        let hints: Hint[] = []

        if (workperiod.periodtype !== 'work' || workperiod.id !== -1) {
            return hints
        }

        const duration = differenceInDays(setHours(workperiod.enddate, 0), setHours(workperiod.startdate, 0))

        const leavingHomeDiff =
            useTravelTime && workperiod.leaving_home
                ? differenceInHours(workperiod.leaving_home, workperiod.startdate)
                : 0

        const returningHomeDiff =
            useTravelTime && workperiod.returning_home
                ? differenceInHours(workperiod.returning_home, workperiod.enddate)
                : 0

        let d = addDays(workperiod.enddate, repeat.days + 1)

        while (isBefore(d, repeat.until)) {
            const start = d
            const end = addDays(d, duration)

            let hint: Hint = {
                start: setHours(start, getHours(workperiod.startdate)),
                end: setHours(end, getHours(workperiod.enddate))
            }

            if (useTravelTime) {
                hint.leaving_home = addHours(hint.start, leavingHomeDiff)
                hint.returning_home = addHours(hint.end, returningHomeDiff)
            }

            hints.push(hint)

            d = addDays(end, repeat.days + 1)
        }

        return hints
    }, [workperiod, repeat, useTravelTime])

    const isNonWorkperiod =
        workperiod.periodtype === 'approved_absence' || workperiod.periodtype === 'unapproved_absence'

    return (
        <Box
            as="form"
            onSubmit={(e: any) => {
                e.preventDefault()
                onSubmit(getHints())
            }}
            flex="1"
            p={4}
        >
            <VStack>
                <HStack>
                    <Input.Radio
                        value="work"
                        name="type-periodtype"
                        onChange={onTypeChange}
                        checked={workperiod.periodtype === 'work'}
                    >
                        {Translated('workperiod')}
                    </Input.Radio>
                    <Input.Radio
                        value="unapproved_absence"
                        name="type-vacation_time"
                        onChange={onTypeChange}
                        checked={isNonWorkperiod}
                    >
                        {Translated('non_workperiod')}
                    </Input.Radio>
                    <Spacer />
                </HStack>
                {workperiod.periodtype === 'work' && <WorkTimeDates workperiod={workperiod} onChange={onChange} />}
                {isNonWorkperiod && <VacationTimeDates workperiod={workperiod} onChange={onChange} />}
                {isNonWorkperiod && (
                    <HStack spacing={0}>
                        <Label>{Translated('reason')}</Label>
                        <HStack flex={1}>
                            {absence.map(x => (
                                <Input.Radio
                                    name={'absence-' + x.id}
                                    key={x.id}
                                    onChange={onAbsenceChange}
                                    value={x.id}
                                    checked={x.id === workperiod.absence_reason_id}
                                    m={0}
                                >
                                    {x.description}
                                </Input.Radio>
                            ))}
                        </HStack>
                    </HStack>
                )}
                <VStack spacing={4} mt={3}>
                    <HStack spacing={0} alignItems="flex-start">
                        <Label>{Translated('notes')}</Label>
                        <HStack flex={1}>
                            <Input
                                value={workperiod.comment || ''}
                                placeholder="..."
                                onChange={(e: any) => {
                                    e.persist()

                                    onChange({
                                        ...workperiod,
                                        comment: e.target.value
                                    })
                                }}
                                width="100%"
                                as="textarea"
                                css={{ resize: 'vertical' }}
                                m={0}
                                rows="4 "
                                fontSize="xs"
                            />
                        </HStack>
                    </HStack>
                    {isNonWorkperiod && (
                        <Input.Checkbox
                            checked={workperiod.periodtype === 'approved_absence'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onChange({
                                    ...workperiod,
                                    periodtype: e.target.checked ? 'approved_absence' : 'unapproved_absence'
                                })
                            }
                        >
                            {Translated('confirmed')}
                        </Input.Checkbox>
                    )}
                    {workperiod.periodtype === 'work' && workperiod.id === -1 && (
                        <VStack>
                            <Input.Checkbox
                                checked={shouldRepeat}
                                onChange={(e: any) => {
                                    const leaving_home = workperiod.leaving_home || workperiod.startdate
                                    const returning_home = workperiod.returning_home || workperiod.enddate
                                    const duration = differenceInDays(returning_home, leaving_home) + 1

                                    setRepeat({
                                        days: 2,
                                        until: addDays(returning_home, duration + 2)
                                    })
                                    setShouldRepeat(e.target.checked)
                                }}
                            >
                                Repeat this
                            </Input.Checkbox>
                            {shouldRepeat && (
                                <VStack>
                                    <HStack>
                                        <Label width="auto">with</Label>
                                        <Input
                                            type="number"
                                            onChange={(e: any) => {
                                                setRepeat({
                                                    ...repeat,
                                                    days: Math.max(1, Number(e.target.value))
                                                })
                                            }}
                                            value={repeat.days}
                                            mb={0}
                                            width={100}
                                        />
                                        <Label as="span" width="auto">
                                            days between each work period.
                                        </Label>
                                    </HStack>
                                    <HStack>
                                        <Label width="auto">until</Label>
                                        <Datepicker
                                            onSelect={(d: any) => {
                                                setRepeat({
                                                    ...repeat,
                                                    until: d
                                                })
                                            }}
                                            value={format(repeat.until, 'YYYY-MM-DD')}
                                            width={100}
                                        />
                                    </HStack>
                                    <HStack>
                                        <Label width="auto">hint</Label>
                                        <Label as="span" width="auto">
                                            This will create additional work-periods on these dates:
                                        </Label>
                                    </HStack>
                                    <HStack>
                                        {getHints().map((x, index) => (
                                            <Text
                                                p={1}
                                                key={index}
                                                fontSize="xxs"
                                                bg="pale-olive"
                                                fontWeight="semibold"
                                            >
                                                {`${format(x.start, 'YYYY-MM-DD')} - ${format(x.end, 'YYYY-MM-DD')}`}
                                            </Text>
                                        ))}
                                    </HStack>
                                </VStack>
                            )}
                        </VStack>
                    )}
                </VStack>
                <Spacer />
                {error && <Text color="red">{error.message}</Text>}
                <HStack justifyContent="space-between">
                    <HStack>
                        <Button loading={loading}>{Translated('save')}</Button>
                        <Button
                            variant="tertiary"
                            onClick={(e: any) => {
                                e.preventDefault()
                                goBack()
                            }}
                        >
                            {Translated('cancel')}
                        </Button>
                    </HStack>
                    {type === 'update' && (
                        <Button
                            loading={deleting}
                            onClick={(e: any) => {
                                e.preventDefault()
                                onDelete()
                            }}
                        >
                            {Translated('remove')}
                        </Button>
                    )}
                </HStack>
            </VStack>
        </Box>
    )
}

export const CreateWorkperiodModalContainer: React.FC<WorkperiodModalProps> = ({ onUpdate }) => {
    const history = useHistory()
    const params = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const user_id = Number(params.user_id)
    const user = useAsync(
        () =>
            timeline
                .getUsers('', {
                    '_filter.id': user_id
                })
                .then(users => users[0]),
        []
    )

    if (user.result) {
        return <CreateWorkperiodModal onDelete={(w: WorkperiodSingle) => {}} user={user.result} onUpdate={onUpdate} />
    }

    return null
}

export const CreateWorkperiodModal: React.FC<WorkperiodModalProps & { user: User }> = ({
    user,
    onUpdate,
    ...props
}) => {
    interface Hint {
        start: Date
        end: Date
        leaving_home?: Date
        returning_home?: Date
    }

    interface CreateActionPayload {
        actions: {
            description: string
            action: string
        }[]
    }

    const [actionPayload, setActionPayload] = React.useState<CreateActionPayload | null>(null)

    const history = useHistory()
    const params = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const user_id = Number(params.user_id)
    const team_id = Number(params.team_id)
    const team = user.teams.find(x => x.id === team_id)
    const useTravelTime = team ? team.use_travel_time : false

    const [tmpHints, setTmpHints] = React.useState<Hint[] | null>(null)

    const create = useAsyncCallback(timeline.createWorkperiod)
    const absenceData = useAsyncAbortable(signal => Admin.getAbsence(signal), [])

    const defaultStart = setHours(parse(params.start || new Date().toString()), 7)
    const defaultEnd = addDays(setHours(defaultStart, 16), 9)

    const [workperiod, update] = React.useState<WorkperiodSingle>({
        id: -1,
        periodtype: 'work',
        startdate: defaultStart,
        enddate: defaultEnd,
        comment: '',
        shiftperiods: [],
        use_travel_time: useTravelTime,
        leaving_home: useTravelTime ? setHours(defaultStart, 7) : undefined,
        returning_home: useTravelTime ? setHours(defaultEnd, 16) : undefined,
        user_id
    })

    const onSubmit = (hints: Hint[], action?: { description: string; action: string }) => {
        if (!useTravelTime) {
            delete workperiod.leaving_home
            delete workperiod.returning_home
        }

        let extraWorkperiods: WorkperiodSingle[] = []

        hints.forEach(d => {
            const w = {
                ...workperiod,
                startdate: d.start,
                enddate: d.end
            }

            if (useTravelTime) {
                w.leaving_home = d.leaving_home
                w.returning_home = d.returning_home
            }

            extraWorkperiods.push(w)
        })

        setActionPayload(null)

        create
            .execute([workperiod, ...extraWorkperiods], team_id, action)
            .then((workperiods: any) => {
                history.goBack()
                onUpdate(workperiods)
                setTmpHints(null)
            })
            .catch(e => {
                if (e.actions) {
                    setTmpHints(hints)
                    setActionPayload({
                        actions: e.actions
                    })
                }
            })
    }

    const onCancel = () => {
        const payload = actionPayload as CreateActionPayload
        setActionPayload(null)
        setTmpHints(null)
    }

    const onOk = () => {
        const payload = actionPayload as CreateActionPayload
        const action = payload.actions[0]

        if (tmpHints) {
            onSubmit(tmpHints, action)
        }
    }

    return (
        <>
            <Modal>
                {({ goBack }) => (
                    <Box
                        minWidth={968}
                        minHeight={460}
                        bg="#ffffff"
                        css={{
                            boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                        }}
                        {...props}
                    >
                        {absenceData.loading && <Loader />}
                        {absenceData.error && (
                            <Box minHeight={100}>
                                <Text>{absenceData.error.message}</Text>
                            </Box>
                        )}
                        {absenceData.result && (
                            <WorkperiodForm
                                useTravelTime={useTravelTime}
                                absence={absenceData.result}
                                error={create.error}
                                loading={create.loading}
                                onSubmit={onSubmit}
                                onChange={update}
                                workperiod={workperiod}
                                goBack={goBack}
                                type="create"
                                deleting={false}
                                onDelete={() => {}}
                            />
                        )}
                    </Box>
                )}
            </Modal>
            {actionPayload && (
                <Confirm message={actionPayload.actions[0].description} onOk={onOk} onCancel={onCancel} />
            )}
        </>
    )
}

export const UpdateWorkperiodModalContainer: React.FC<WorkperiodModalProps> = ({ onUpdate, onDelete }) => {
    return <UpdateWorkperiodModal onDelete={onDelete} onUpdate={onUpdate} />
}

export const UpdateWorkperiodModal: React.FC<WorkperiodModalProps> = ({ onUpdate, onDelete, ...props }) => {
    const params = useParams()
    const history = useHistory()
    const id = params.id || '2'

    const data = useAsync(() => timeline.getWorkperiod(id), [id], {
        setLoading: state => ({ ...state, loading: true })
    })
    const absenceData = useAsyncAbortable(signal => Admin.getAbsence(signal), [])

    const update = useAsyncCallback((w: WorkperiodSingle) => timeline.updateWorkperiod(w))
    const remove = useAsyncCallback((w: WorkperiodSingle) => timeline.removeWorkperiod(w))

    const workperiod = data.result

    const onSubmit = (hints: { start: Date; end: Date }[]) => {
        if (workperiod) {
            const w = workperiod

            if (!w.use_travel_time) {
                delete w.leaving_home
                delete w.returning_home
            }

            update.execute(w).then(newWorkperiod => {
                onUpdate(newWorkperiod)
                history.goBack()
            })
        }
    }

    const onLocalDelete = () => {
        if (workperiod) {
            remove.execute(workperiod).then(() => {
                onDelete(workperiod)
                history.goBack()
            })
        }
    }

    const loading = absenceData.loading || data.loading
    const error = absenceData.error || data.error

    const absence = absenceData.result

    return (
        <Modal>
            {({ goBack }) => (
                <Box
                    minWidth={968}
                    minHeight={460}
                    bg="#ffffff"
                    css={{
                        boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                    }}
                    {...props}
                >
                    {loading && <Loader />}
                    {error && (
                        <Box position="relative">
                            <Text>{error.message}</Text>
                        </Box>
                    )}
                    {workperiod && absence && (
                        <WorkperiodForm
                            useTravelTime={workperiod.use_travel_time}
                            absence={absence}
                            loading={update.loading}
                            error={update.error}
                            onSubmit={onSubmit}
                            onChange={w => data.setResult(w)}
                            workperiod={workperiod}
                            goBack={goBack}
                            deleting={remove.loading}
                            onDelete={onLocalDelete}
                            type="update"
                        />
                    )}
                    {remove.error && <Text>{remove.error.message}</Text>}
                </Box>
            )}
        </Modal>
    )
}
