import React from 'react'
import { BoxProps } from './Box'
import { HStack, StackProps } from './Layout'
import Button from './Button'

interface PillProps extends BoxProps {
    isSelected: boolean
}

export const Pill: React.FunctionComponent<PillProps> = ({ isSelected, ...props }) => (
    <Button
        px={2}
        py={1}
        fontSize="sm"
        lineHeight="1.375rem"
        css={{
            transition: 'none',
            bg: isSelected ? 'teal-blue' : 'offwhite',
            color: isSelected ? 'white' : 'black',
            border: 'none',
            '&:hover': {}
        }}
        {...props}
    />
)

const PillGroup: React.FunctionComponent<StackProps> = props => {
    return <HStack spacing={0} {...props}></HStack>
}

export default PillGroup
