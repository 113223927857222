import React from 'react'
import Loader from './Loader'
import Box, { BoxProps } from './Box'

const variants = {
    touchable: {
        p: 0,
        m: 0,
        border: 0,
        my: 0,
        py: 0,
        px: 0,
        userSelect: 'none',
        bg: 'transparent',
        css: {
            '&:hover': {}
        }
    },
    tertiary: {
        bg: 'offwhite',
        color: 'black',
        border: 1,
        borderColor: 'offwhite',
        css: {
            '&:hover': {
                bg: 'midgrey',
                borderColor: 'midgrey'
            }
        }
    },
    secondary: {
        bg: 'white',
        color: 'black',
        borderColor: 'brownish-grey',
        border: 1,
        css: {
            '&:hover': {
                borderColor: 'pale-olive'
            },
            '&:disabled': {
                color: 'midgrey',
                borderColor: 'midgrey',
                bg: 'white'
            }
        }
    }
}

type ButtonProps = {
    loading?: boolean
    disabled?: boolean
    variant?: keyof typeof variants
} & BoxProps

type Button = React.FunctionComponent<ButtonProps>

const getVariant = (variant: keyof typeof variants | undefined) => {
    let variantProps = variant !== undefined ? variants[variant] : undefined
    if (variantProps !== undefined) {
        const { css, ...rest } = variantProps
        return [css, rest]
    }
    return [{}, {}]
}

export const Touchable: React.FC<ButtonProps> = props => <Button variant="touchable" {...props}></Button>

const Button = React.forwardRef<unknown, ButtonProps>(
    ({ children, variant, disabled, loading, css, ...props }, ref) => {
        const [variantCss, v] = getVariant(variant)

        return (
            <Box
                as="button"
                ref={ref}
                px={3}
                pt={2}
                pb={2}
                fontSize="sm"
                fontWeight="semibold"
                overflow="hidden"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                bg="pale-olive"
                color="black"
                border={1}
                borderColor="pale-olive"
                css={{
                    transition: ['0.25s background ease', '0.25s border-color ease'].join(','),
                    pointerEvents: disabled || loading ? 'none' : undefined,
                    textDecoration: 'none',
                    backfaceVisibility: 'hidden',

                    '&:active': {
                        transform: 'translateY(1px)'
                    },

                    '&:hover': {
                        bg: 'light-yellow-green',
                        borderColor: 'light-yellow-green'
                    },

                    '&:disabled': {
                        bg: 'offwhite',
                        color: 'black-transparent',
                        borderColor: 'offwhite'
                    },

                    ...variantCss,
                    ...css
                }}
                {...(disabled && { disabled: true })}
                {...v}
                {...props}
            >
                <>
                    {loading && <Loader data-testid="loader" bg="inherit" color="inherit" size={16} />}
                    {children}
                </>
            </Box>
        )
    }
)

export default Button
