import React from 'react'
import { animated } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import Box, { BoxProps } from './Box'
import { ScaleTime } from 'd3-scale'
import startOfDay from 'date-fns/start_of_day'
import getHours from 'date-fns/get_hours'
import addDays from 'date-fns/add_days'

export function getSteppedValue(v: number, step: number, rounding = Math.round) {
    const value = rounding(v / step) * step
    return value
}
export function roundToNearestDay(d: Date) {
    if (getHours(d) > 12) {
        return startOfDay(addDays(d, 1))
    } else {
        return startOfDay(d)
    }
}

export function getSteppedDateValue(scale: ScaleTime<number, number>, val: number) {
    return scale(roundToNearestDay(scale.invert(val)))
}

type PositionChangeHandler = (x: number, y: number, width: number) => void

interface DraggableProps extends BoxProps {
    bounds: {
        x: [number, number]
        y: [number, number]
    }
    step: number
    left: number
    width: number
    top: number
    disableDrag?: boolean
    disableResize?: boolean
    isCutting: boolean
    rowHeight?: number
    index: number
    scale: ScaleTime<number, number>
    onPositionChange: PositionChangeHandler
    onTap?: (e: any) => void
    children: (isDragging: boolean, isCutting: boolean) => React.ReactNode
}

const Draggable: React.FunctionComponent<DraggableProps> = ({
    step,
    bounds,
    isCutting,
    rowHeight = 43,
    width,
    left,
    top,
    index,
    disableDrag = false,
    disableResize = false,
    onTap,
    onPositionChange,
    children,
    scale,
    ...props
}) => {
    const [[x, y], setLocal] = React.useState([left, top])
    const [isDragging, setDragging] = React.useState(false)
    const [isResizing, setResizing] = React.useState(false)
    const [w, setWidth] = React.useState(width)

    const lastDrag = React.useRef(Date.now())

    const onDrag = React.useCallback(
        ({ movement: [mx, my], dragging, time }) => {
            if (isCutting || disableDrag) return

            if (isDragging !== dragging) {
                setDragging(dragging)
            }

            lastDrag.current = time

            const newX = getSteppedDateValue(scale, getSteppedValue(left + mx, step))
            const newY = Math.min(bounds.y[1], Math.max(bounds.y[0], getSteppedValue(top + my, rowHeight)))

            if (newX !== x || newY !== y) {
                setLocal([newX, newY])
            }
        },
        [left, top, x, y, isDragging, isCutting, disableDrag, bounds, rowHeight, scale, step]
    )

    const onPChange = React.useCallback(
        ({ time }) => {
            if (!isCutting) {
                onPositionChange(x, y, w)
            }
        },
        [onPositionChange, isCutting, w, x, y]
    )

    const onClick = React.useCallback(
        (e: any) => {
            if (onTap && !isDragging && !isResizing) {
                onTap(e)
            }
        },
        [onTap, isResizing, isDragging]
    )

    const bindTopLeft = useGesture(
        {
            onPointerUp: onClick,
            onDragEnd: onPChange,
            onDrag: onDrag
        },
        { dragDelay: true }
    )

    const onResizeDrag = React.useCallback(
        ({ movement: [mx, my], dragging, event, args: [direction] }) => {
            if (isCutting || disableResize) return

            if (event) {
                event.stopPropagation()
            }

            if (isResizing !== dragging) {
                setResizing(dragging)
            }

            if (direction === 'right') {
                const steppedDelta = getSteppedValue(mx, step)

                const newWidth = Math.max(bounds.x[1] - left, Math.max(step, width + steppedDelta))

                if (w !== newWidth) {
                    setWidth(newWidth)
                }
            } else {
                const steppedDelta = getSteppedValue(mx, step)
                const newX = Math.min(bounds.x[0], getSteppedValue(left + steppedDelta, step))
                const newWidth = Math.max(bounds.x[1] - bounds.x[0], Math.max(0, width + (left - newX)))

                if (newWidth !== 0) {
                    if (newX !== x) {
                        setLocal([newX, y])
                    }
                    if (newX <= bounds.x[0]) {
                        if (w !== newWidth) {
                            setWidth(newWidth)
                        }
                    }
                }
            }
        },
        [width, left, y, x, w, bounds.x, isCutting, disableResize, isResizing, step]
    )

    const bindLeft = useGesture({
        onDragEnd: onPChange,
        onDrag: onResizeDrag
    })

    const bindRight = useGesture({
        onDragEnd: onPChange,
        onDrag: onResizeDrag
    })

    return (
        <Box
            as={animated.div}
            zIndex={isDragging ? 2 : 1}
            position="absolute"
            css={{
                '.Text': { userSelect: 'none' },
                cursor: isDragging ? 'move' : isResizing ? 'ew-resize' : 'pointer'
            }}
            style={{
                willChange: 'width, transform',
                overflow: 'hidden',
                width: w,
                transform: `translate3d(${x}px, ${y}px, 0)`
            }}
            {...bindTopLeft()}
            {...props}
        >
            <Box
                position="absolute"
                left={-4}
                top={0}
                width={3}
                px={1}
                borderRadius={2}
                height="100%"
                zIndex={3}
                css={{ cursor: 'ew-resize' }}
                className="draghandle"
                {...bindLeft('left')}
            >
                <Box width={3} height="100%" borderRadius={2} bg="rgba(51, 51, 51, 0.2)"></Box>
            </Box>
            <Box
                position="absolute"
                right={-4}
                top={0}
                height="100%"
                px={1}
                zIndex={3}
                css={{ cursor: 'ew-resize' }}
                className="draghandle"
                {...bindRight('right')}
            >
                <Box width={3} height="100%" borderRadius={2} bg="rgba(51, 51, 51, 0.2)"></Box>
            </Box>
            {children(isDragging, isCutting)}
        </Box>
    )
}

export default Draggable
