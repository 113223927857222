import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import Text from './Text'
import Box from './Box'

const ErrorFallback: React.FC<FallbackProps> = ({ componentStack, error }) => {
    return (
        <Box bg="rgba(255, 0, 0, 0.1)" p={3}>
            <Text p={3} color="red" fontSize="sm">
                {componentStack ? 'Ojdå, något gick fel... Oväntat fel.' : error && error.toString()}
            </Text>
        </Box>
    )
}

export default ErrorFallback
