import React, { SyntheticEvent } from 'react'
import Box, { BoxProps } from './Box'
import { HStack } from './Layout'
import Text from './Text'
import { CheckboxFilled } from './Icon'

interface CheckboxProps extends BoxProps {
    name?: string
    htmlFor?: string
    onChange?: React.EventHandler<SyntheticEvent<HTMLInputElement>>
    checked?: boolean
}

interface RadioProps extends CheckboxProps {
    value?: string | number
}

interface Input extends React.FunctionComponent<BoxProps> {
    NativeSelect: React.FunctionComponent<BoxProps>
    Checkbox: React.FunctionComponent<CheckboxProps>
    Radio: React.FunctionComponent<RadioProps>
}

const Input: Input = ({ css, ...props }) => (
    <Box
        as="input"
        p={2}
        mb={3}
        lineHeight="normal"
        border="1px solid"
        bg="offwhite"
        borderColor="offwhite"
        fontSize="sm"
        fontWeight="semibold"
        color="black"
        width="100%"
        css={{
            userSelect: 'text',
            '&:hover': {
                outline: 'none',
                borderColor: 'grey-darkest'
            },

            '&:focus': {
                outline: 'none',
                borderColor: 'grey-darkest'
            },

            transition: '250ms border-color ease',
            ...css
        }}
        {...props}
    />
)

Input.NativeSelect = props => (
    <Input
        as="select"
        fontSize="sm"
        borderRadius={0}
        css={{
            outline: 'none',
            appearance: 'none',
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5'%3E%3Cpath fill='%23333' fill-rule='evenodd' d='M7.067.63L4 3.004.933.63 0 1.87l4 3 4-3z'/%3E%3C/svg%3E%0A") no-repeat #f0f0f0`,
            backgroundPosition: 'calc(100% - 8px) calc(50% - 2px)',
            '&[multiple]': {
                background: 'none'
            }
        }}
        {...props}
    />
)

Input.Checkbox = ({ name, disabled, onChange, checked, children, ...props }) => (
    <HStack
        as="label"
        spacing={2}
        justifyContent="baseline"
        htmlFor={name}
        css={{
            userSelect: 'none',
            input: { display: 'none' },
            p: { userSelect: 'none' },
            'input:checked + .box': {
                bg: 'pale-olive',
                borderColor: 'pale-olive'
            },
            'input:checked + .box svg': {
                opacity: 1
            },
            'input:not(:checked) + .box': { opacity: 1 }
        }}
        {...props}
    >
        <input disabled={disabled} type="checkbox" name={name} onChange={onChange} checked={checked} />

        <Box
            className="box"
            width={24}
            height={24}
            display="flex"
            alignItems="center"
            justifyContent="center"
            border={1}
            borderColor="brownish-grey"
            css={{ transition: '.2s all linear' }}
        >
            <CheckboxFilled opacity={0} />
        </Box>

        <Text mb={0} fontSize="sm" lineHeight="1rem" letterSpacing="-0.07px" fontWeight="semibold" color="black">
            {children}
        </Text>
    </HStack>
)

Input.Radio = ({ name, value, disabled, checked, onChange, bg, color, children, ...props }) => (
    <HStack
        as="label"
        spacing={2}
        justifyContent="baseline"
        htmlFor={name}
        css={{
            opacity: disabled ? 0.5 : 1,
            userSelect: 'none',
            input: { display: 'none' },
            p: { userSelect: 'none' },
            'input:checked + .box .circle': {
                opacity: 1
            }
        }}
        {...props}
    >
        <input
            type="radio"
            disabled={disabled}
            name={name}
            id={name}
            value={value}
            checked={checked}
            onChange={onChange}
        />

        <Box
            className="box"
            width={22}
            height={22}
            border={1}
            borderRadius="100%"
            borderColor="brownish-grey"
            css={{ flexShrink: 0 }}
            display="flex"
            bg={bg}
            justifyContent="center"
            alignItems="center"
        >
            <Box
                className="circle"
                width={12}
                height={12}
                bg={color || 'pale-olive'}
                opacity={0}
                borderRadius="100%"
            ></Box>
        </Box>

        <Text mb={0} fontSize="sm" lineHeight="1rem" letterSpacing="-0.07px" fontWeight="semibold" color="black">
            {children}
        </Text>
    </HStack>
)

export default Input
