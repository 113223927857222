import React from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Box from '../components/Box'
import { useAsyncAbortable } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import { TableRow, TableHeader, TableColumn } from '../components/Table'
import { Touchable } from '../components/Button'
import { BigAdd, Menu } from '../components/Icon'
import Link from '../components/Link'
import Text from '../components/Text'
import { AdminMachine } from '../types/admin'

interface PageProps {}

export const DragHandle = SortableHandle(() => (
    <Box as="span" mr={2}>
        <Menu />
    </Box>
))

const Machine = SortableElement(({ machine }: { machine: AdminMachine }) => (
    <TableRow key={machine.id}>
        <TableColumn>
            <DragHandle />
            <Text
                as={Link}
                fontWeight="semibold"
                css={{
                    textDecoration: 'none'
                }}
                color="black"
                to={'/admin/machines/' + machine.id}
            >
                {machine.name}
            </Text>
        </TableColumn>
        <TableColumn>
            <Box borderRadius="100%" width={22} height={22} bg={machine.color}></Box>
        </TableColumn>
        <TableColumn>{machine.location}</TableColumn>
    </TableRow>
))

const MachinesList = SortableContainer(({ machines }: { machines: AdminMachine[] }) => {
    return (
        <tbody style={{ width: 661 }}>
            <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Color</TableHeader>
                <TableHeader>Location</TableHeader>
                <TableHeader textAlign="right">
                    <Box as={Link} to="/admin/machines/add">
                        <Touchable>
                            <BigAdd />
                        </Touchable>
                    </Box>
                </TableHeader>
            </TableRow>
            {machines.map((machine, index) => (
                <Machine key={`machine-${machine.id}`} index={index} machine={machine} />
            ))}
        </tbody>
    )
})

const MachinesPage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {
    const data = useAsyncAbortable(abortSignal => Admin.getMachines(abortSignal), [])

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        if (data.result) {
            const newMachihes = arrayMove(data.result, oldIndex, newIndex).map((x, index) => {
                x.order = index
                return x
            })

            data.setResult(newMachihes)

            Admin.updateMachines(newMachihes)
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {data.result && (
                <Box as="table" mt={4} css={{ borderCollapse: 'collapse' }} width={661} mx="auto">
                    <MachinesList
                        machines={data.result}
                        lockAxis="y"
                        onSortEnd={onSortEnd}
                        helperClass="sorting-row"
                        useDragHandle
                    />
                </Box>
            )}
        </Box>
    )
}

export default MachinesPage
