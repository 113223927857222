import React, { FormEvent, SetStateAction } from 'react'
import qs from 'qs'
import Box, { BoxProps } from '../components/Box'
import Input from '../components/Input'
import { HStack, Divider, VStack, Spacer } from '../components/Layout'
import Button from '../components/Button'
import Text from '../components/Text'
import Datepicker from '../components/Datepicker'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import addHours from 'date-fns/add_hours'
import { ArrowDown, Add } from '../components/Icon'
import { useAsync, useAsyncCallback } from '../hooks/useAsync'
import { timeline } from '../api'
import Loader from '../components/Loader'
import Modal from '../components/Modal'
import { User } from '../types/users'
import { useHistory, useParams } from 'react-router-dom'
import { addDays, isSameDay } from 'date-fns'
// @ts-ignore
import InputMask from 'react-input-mask'
import Confirm from '../components/Confirm'
import { Translated } from '../context/LocaleContext'
import { Select } from '../components/Select'
import { UserContext } from '../App'

interface WorkOrderModalProps extends BoxProps {
    onUpdate: (workorder: WorkOrder) => void
    onDelete: (workorder: WorkOrder) => void
}

interface WorkorderDaysProps {
    workorder: WorkOrder
    onChange: (w: WorkOrder) => void
    dayIndex?: number
    users?: User[]
}

const WorkorderDays: React.FC<WorkorderDaysProps> = ({ workorder, onChange, dayIndex, users }) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const canEdit = role === 'admin' || role === 'staff_planner' || role === 'project_manager'
    const [dayState, setDayState] = React.useState(
        workorder.days.map((x, index) => {
            return index === dayIndex && x.shifts.length > 0
        })
    )

    const toggleDay = (dayIndex: number) => {
        const newDayState = [...dayState]
        newDayState[dayIndex] = !newDayState[dayIndex]
        setDayState(newDayState)
    }

    const updateShift = (dayIndex: number, shiftIndex: number, s: WorkOrderShift) => {
        const draft = { ...workorder }
        const day = draft.days[dayIndex]
        day.shifts[shiftIndex] = s
        onChange(draft)
    }

    const canAddShift = (dayIndex: number) => {
        const draft = { ...workorder }
        const day = draft.days[dayIndex]
        const nextDay = draft.days[dayIndex + 1]
        const prevShift = day.shifts[day.shifts.length - 1]

        const dayDate = parse(day.date)

        // TODO: refactor this mess

        if (prevShift) {
            const d = addHours(parse(format(dayDate, 'YYYY-MM-DD ') + prevShift.starttime), prevShift.hours)
            if (isSameDay(dayDate, d)) {
                return true
            } else if (nextDay) {
                if (nextDay.shifts.length === 0) {
                    return true
                }
            }
        } else {
            return true
        }

        return false
    }

    const removeShift = (dayIndex: number, shiftIndex: number) => {
        const draft = { ...workorder }

        const day = draft.days[dayIndex]

        day.shifts.splice(shiftIndex, 1)

        if (day.shifts.length === 0) {
            day.open = false
        }

        onChange(draft)
    }

    const addShift = (dayIndex: number) => {
        const draft = { ...workorder }
        const day = draft.days[dayIndex]
        const nextDay = draft.days[dayIndex + 1]
        const prevShift = day.shifts[day.shifts.length - 1]

        const dayDate = parse(day.date)

        if (prevShift) {
            const d = addHours(parse(format(dayDate, 'YYYY-MM-DD ') + prevShift.starttime), prevShift.hours)

            const shift = {
                starttime: format(d, 'HH:mm'),
                hours: 8,
                num_people: workorder.operators,
                notes: '',
                people: []
            }

            if (isSameDay(dayDate, d)) {
                day.shifts.push(shift)
            } else {
                if (nextDay) {
                    nextDay.shifts.push(shift)
                }
            }
        } else {
            day.shifts.push({
                starttime: '22:00',
                num_people: workorder.operators,
                hours: 8,
                notes: '',
                people: []
            })
        }

        onChange(draft)
    }

    return (
        <>
            <Divider />
            <Box flex="1" py={4}>
                <Text fontSize="sm" fontWeight="semibold" color="black">
                    {Translated('shift')}
                </Text>
                <VStack spacing={1}>
                    {workorder.days.map((day, dayIndex) => (
                        <>
                            <Box key={dayIndex}>
                                <HStack spacing={2}>
                                    <HStack
                                        spacing={1}
                                        width={120}
                                        css={{
                                            flexShrink: 0,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            if (day.shifts.length) {
                                                toggleDay(dayIndex)
                                            }
                                        }}
                                    >
                                        <ArrowDown
                                            css={{
                                                flexShrink: 0,
                                                transform: day.open ? 'rotate(0deg)' : 'rotate(-90deg)'
                                            }}
                                        />
                                        <Text
                                            fontSize="xs"
                                            fontWeight="semibold"
                                            color="black"
                                            css={{
                                                userSelect: 'none',
                                                flexShrink: 0
                                            }}
                                            m={0}
                                        >
                                            {format(day.date, 'dddd DD/M')}
                                        </Text>
                                    </HStack>
                                    <HStack
                                        css={{
                                            flexWrap: 'wrap',
                                            '> *': { mb: 1 }
                                        }}
                                    >
                                        {day.shifts.map((s, shiftIndex) => (
                                            <HStack spacing={1} key={shiftIndex}>
                                                <Input.NativeSelect
                                                    disabled={!canEdit}
                                                    value={s.hours}
                                                    onChange={(e: any) => {
                                                        updateShift(dayIndex, shiftIndex, {
                                                            ...s,
                                                            hours: e.target.value
                                                        })
                                                    }}
                                                    width="auto"
                                                    height={24}
                                                    p={0}
                                                    pl={2}
                                                    pr="1.5rem"
                                                    m={0}
                                                    fontSize="xs"
                                                >
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => (
                                                        <option value={x}>{x}h</option>
                                                    ))}
                                                </Input.NativeSelect>

                                                <Input
                                                    as={InputMask}
                                                    disabled={!canEdit}
                                                    height={24}
                                                    width={64}
                                                    p={0}
                                                    mask="99:99"
                                                    px={2}
                                                    m={0}
                                                    fontSize="xs"
                                                    css={{
                                                        WebkitAppearance: 'none',
                                                        background: 'offwhite'
                                                    }}
                                                    value={s.starttime.toString()}
                                                    onChange={(e: any) => {
                                                        updateShift(dayIndex, shiftIndex, {
                                                            ...s,
                                                            starttime: e.target.value
                                                        })
                                                    }}
                                                />
                                            </HStack>
                                        ))}

                                        {day.shifts.length < 3 && canAddShift(dayIndex) && canEdit && (
                                            <Button
                                                variant="touchable"
                                                border="none"
                                                onClick={() => addShift(dayIndex)}
                                            >
                                                <Add />
                                            </Button>
                                        )}
                                    </HStack>
                                </HStack>
                                {dayState[dayIndex] ? (
                                    <VStack my={3}>
                                        {day.shifts.map((s, shiftIndex) => {
                                            const peopleOptions = users
                                                ? users
                                                      .filter(u => u.role === 4)
                                                      .map(x => ({ label: x.name, value: x.id }))
                                                : []

                                            return (
                                                <HStack key={shiftIndex} spacing={0} alignItems="flex-start">
                                                    <Text fontSize="sm" fontWeight="semibold" m={0} width={66}>
                                                        {s.starttime}
                                                    </Text>
                                                    <VStack flex={0}>
                                                        <Input
                                                            height={24}
                                                            fontSize="xs"
                                                            disabled={!canEdit}
                                                            width={250}
                                                            m={0}
                                                            value={s.notes}
                                                            onChange={(e: any) => {
                                                                updateShift(dayIndex, shiftIndex, {
                                                                    ...s,
                                                                    notes: e.target.value
                                                                })
                                                            }}
                                                        />
                                                        <Box width={250}>
                                                            <Select
                                                                placeholder="Pick operators"
                                                                disabled={!canEdit}
                                                                isMulti={true}
                                                                value={s.people.map(x => ({
                                                                    label: x.name,
                                                                    value: x.user_id
                                                                }))}
                                                                onChange={value => {
                                                                    const newShift = { ...s }

                                                                    if (Array.isArray(value) && users) {
                                                                        newShift.people = users
                                                                            .filter(x =>
                                                                                value.find(y => y.value === x.id)
                                                                            )
                                                                            .map(
                                                                                x =>
                                                                                    ({
                                                                                        user_id: x.id,
                                                                                        name: x.name as string
                                                                                    } as WorkOrderPeople)
                                                                            )
                                                                    } else {
                                                                        newShift.people = []
                                                                    }

                                                                    updateShift(dayIndex, shiftIndex, newShift)
                                                                }}
                                                                options={peopleOptions}
                                                            />
                                                        </Box>
                                                    </VStack>
                                                    <Button
                                                        onClick={() => removeShift(dayIndex, shiftIndex)}
                                                        disabled={!canEdit}
                                                        ml={3}
                                                        bg="transparent"
                                                        border="none"
                                                        px={0}
                                                        py={0}
                                                        css={{ '&:hover': { bg: 'transparent' } }}
                                                    >
                                                        {Translated('remove')}
                                                    </Button>
                                                </HStack>
                                            )
                                        })}
                                    </VStack>
                                ) : null}
                            </Box>
                            <Divider key={'divider' + dayIndex} mx={3} bg="offwhite" />
                        </>
                    ))}
                </VStack>
            </Box>
        </>
    )
}

interface WorkOrderFormProps {
    dayIndex?: number
    workorder: WorkOrder
    users?: User[]
    onSubmit: (e: FormEvent) => void
    onChange: React.Dispatch<SetStateAction<WorkOrder>>
    onRemove: (w: WorkOrder) => void
    onDateChange?: (start: string, end: string) => void
    error: any
    loading: boolean
    removeing?: boolean
    goBack: () => void
}

const WorkOrderForm: React.FC<WorkOrderFormProps> = ({
    dayIndex,
    error,
    loading,
    removeing = false,
    workorder,
    users,
    goBack,
    onChange,
    onRemove,
    onDateChange,
    onSubmit
}) => {
    const { user } = React.useContext(UserContext)
    const role = user && user.user_role
    const canEdit = role === 'admin' || role === 'staff_planner' || role === 'project_manager'

    const onTypeChange = (e: any) => {
        e.persist()

        onChange({
            ...workorder,
            type: e.target.value
        })
    }

    const startChange = (start: Date) => {
        if (onDateChange) {
            onDateChange(format(start, 'YYYY-MM-DD'), workorder.enddate)
        } else {
            onChange({
                ...workorder,
                startdate: format(start, 'YYYY-MM-DD')
            })
        }
    }

    const endChange = (end: Date) => {
        if (onDateChange) {
            onDateChange(workorder.startdate, format(end, 'YYYY-MM-DD'))
        } else {
            onChange({
                ...workorder,
                enddate: format(end, 'YYYY-MM-DD')
            })
        }
    }

    const onDownload = (e: any) => {
        e.preventDefault()
        timeline.downloadWorkorder(workorder.id)
    }

    const isUpdateForm = workorder.type === 'workorder' && workorder.id !== -1

    const templateShift = workorder.templateshifts && workorder.templateshifts[0]

    return (
        <>
            <Box as="form" onSubmit={onSubmit} flex="1" p={4}>
                <VStack>
                    <Input
                        value={workorder.name}
                        name="name"
                        disabled={!canEdit}
                        onChange={(e: any) => {
                            e.persist()
                            onChange({
                                ...workorder,
                                name: e.target.value
                            })
                        }}
                    />
                    <HStack>
                        <Input.Radio
                            disabled={!canEdit || (workorder.type === 'workorder' && workorder.id !== -1)}
                            value="prospect"
                            name="type-prospekt"
                            onChange={onTypeChange}
                            checked={workorder.type === 'prospect'}
                        >
                            {Translated('prospect')}
                        </Input.Radio>
                        <Input.Radio
                            disabled={!canEdit}
                            value="workorder"
                            name="type-workorder"
                            onChange={onTypeChange}
                            checked={workorder.type === 'workorder'}
                        >
                            {Translated('workorder')}
                        </Input.Radio>
                    </HStack>
                    <HStack spacing={0}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" width={100} fontWeight="semibold">
                            {Translated('workperiod')}
                        </Text>
                        <HStack spacing={2}>
                            <Datepicker
                                disabled={!canEdit}
                                width={90}
                                value={format(workorder.startdate, 'YYYY-MM-DD')}
                                onSelect={startChange}
                            />
                            <Text mb={0} fontSize="xs" fontWeight="semibold">
                                –
                            </Text>
                            <Datepicker
                                disabled={!canEdit}
                                width={90}
                                value={format(workorder.enddate, 'YYYY-MM-DD')}
                                onSelect={endChange}
                            />
                            <Input.Checkbox
                                disabled={!canEdit}
                                checked={workorder.backtoback}
                                onChange={(e: any) =>
                                    onChange({
                                        ...workorder,
                                        backtoback: e.target.checked
                                    })
                                }
                            >
                                {Translated('back_to_back')}
                            </Input.Checkbox>
                        </HStack>
                    </HStack>
                    <HStack spacing={0}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                            {Translated('postcode')}
                        </Text>
                        <HStack>
                            <Input
                                disabled={!canEdit}
                                value={workorder.postcode}
                                onChange={(e: any) => {
                                    e.persist()
                                    onChange({
                                        ...workorder,
                                        postcode: e.target.value
                                    })
                                }}
                                m={0}
                                width={79}
                                fontSize="xs"
                            />
                        </HStack>
                    </HStack>
                    <HStack spacing={0}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                            {Translated('address')}
                        </Text>
                        <HStack flex={1}>
                            <Input
                                disabled={!canEdit}
                                value={workorder.address}
                                onChange={(e: any) => {
                                    e.persist()
                                    onChange({
                                        ...workorder,
                                        address: e.target.value
                                    })
                                }}
                                m={0}
                                fontSize="xs"
                            />
                        </HStack>
                    </HStack>
                    <HStack spacing={0}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                            {Translated('project_manager')}
                        </Text>
                        <HStack flex={1}>
                            {!canEdit && <Input disabled value={workorder.projectleader_name} />}
                            {canEdit && (
                                <Input.NativeSelect
                                    m={0}
                                    fontSize="xs"
                                    value={workorder.projectleader_id}
                                    onChange={(e: any) => {
                                        e.persist()
                                        onChange({
                                            ...workorder,
                                            projectleader_id: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Välj projektledare</option>
                                    {users &&
                                        users
                                            .filter(u => u.role === 3 || u.role === 1)
                                            .map(u => (
                                                <option key={u.id} value={u.id}>
                                                    {u.name}
                                                </option>
                                            ))}
                                </Input.NativeSelect>
                            )}
                        </HStack>
                    </HStack>
                    <VStack mt={3}>
                        <HStack spacing={0}>
                            <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                                {Translated('number_of_operators')}
                            </Text>
                            <HStack>
                                <Input
                                    disabled={!canEdit}
                                    value={workorder.operators}
                                    onChange={(e: any) => {
                                        e.persist()

                                        if (/^\d+$/.test(e.target.value)) {
                                            onChange({
                                                ...workorder,
                                                operators: Number(e.target.value)
                                            })
                                        }
                                    }}
                                    m={0}
                                    width={32}
                                    fontSize="xs"
                                />
                            </HStack>
                        </HStack>
                        <HStack spacing={0} alignItems="flex-start">
                            <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                                {Translated('notes')}
                            </Text>
                            <HStack flex={1}>
                                <Input
                                    disabled={!canEdit}
                                    value={workorder.notes || ''}
                                    placeholder="..."
                                    onChange={(e: any) => {
                                        e.persist()

                                        onChange({
                                            ...workorder,
                                            notes: e.target.value
                                        })
                                    }}
                                    width="100%"
                                    as="textarea"
                                    css={{ resize: 'vertical' }}
                                    m={0}
                                    rows="4 "
                                    fontSize="xs"
                                />
                            </HStack>
                        </HStack>
                    </VStack>
                    <Spacer />
                    {error && <Text>{error.message}</Text>}
                    <HStack justifyContent="space-between">
                        {canEdit && (
                            <HStack>
                                <Button loading={loading}>{Translated('save')}</Button>
                                <Button
                                    variant="tertiary"
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        goBack()
                                    }}
                                >
                                    {Translated('cancel')}
                                </Button>
                            </HStack>
                        )}
                        {isUpdateForm && canEdit && (
                            <HStack spacing={2}>
                                <Button onClick={onDownload} variant="tertiary">
                                    {Translated('download_csv')}
                                </Button>
                                <Button loading={removeing} variant="tertiary" onClick={onRemove}>
                                    {Translated('remove')}
                                </Button>
                            </HStack>
                        )}
                    </HStack>
                </VStack>
            </Box>
            {!isUpdateForm && templateShift && (
                <>
                    <Divider />
                    <Box flex="1" py={4}>
                        <Text fontSize="sm" fontWeight="semibold" color="black">
                            {Translated('template_shift')}
                        </Text>
                        <HStack spacing={1}>
                            <Input.NativeSelect
                                value={templateShift.hours}
                                onChange={(e: any) => {
                                    onChange({
                                        ...workorder,
                                        templateshifts: [
                                            {
                                                hours: Number(e.target.value),
                                                starttime: templateShift.starttime
                                            }
                                        ]
                                    })
                                }}
                                width="auto"
                                height={24}
                                p={0}
                                pl={2}
                                pr="1.5rem"
                                m={0}
                                fontSize="xs"
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => (
                                    <option value={x}>{x}h</option>
                                ))}
                            </Input.NativeSelect>

                            <Input
                                as={InputMask}
                                height={24}
                                width={64}
                                p={0}
                                mask="99:99"
                                px={2}
                                m={0}
                                fontSize="xs"
                                css={{
                                    WebkitAppearance: 'none',
                                    background: 'offwhite'
                                }}
                                value={templateShift.starttime}
                                onChange={(e: any) => {
                                    onChange({
                                        ...workorder,
                                        templateshifts: [
                                            {
                                                hours: templateShift.hours,
                                                starttime: e.target.value
                                            }
                                        ]
                                    })
                                }}
                            />
                        </HStack>
                    </Box>
                </>
            )}
            {isUpdateForm && (
                <WorkorderDays workorder={workorder} onChange={onChange} dayIndex={dayIndex} users={users} />
            )}
        </>
    )
}

export const CreateWorkOrderModal: React.FC<WorkOrderModalProps> = ({ onUpdate, ...props }) => {
    const history = useHistory()
    const params = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const machine_id = Number(params.machine_id)
    const create = useAsyncCallback(timeline.createWorkorder)
    const usersData = useAsync(() => timeline.getUsers(''), [], {
        setLoading: state => ({ ...state, loading: true })
    })

    const defaultStart = parse(params.start)
    const defaultEnd = addDays(defaultStart, 3)

    const users = usersData.result

    const [workorder, update] = React.useState<WorkOrder>({
        id: -1,
        name: '',
        type: 'workorder',
        startdate: format(defaultStart, 'YYYY-MM-DD'),
        enddate: format(defaultEnd, 'YYYY-MM-DD'),
        backtoback: false,
        postcode: '',
        address: '',
        projectleader_id: undefined,
        operators: 2,
        notes: '',
        days: [],
        machine_id: machine_id,
        etag: '',
        templateshifts: [
            {
                hours: 8,
                starttime: '22:00'
            }
        ]
    })

    if (!users) {
        return (
            <Modal>
                {({ goBack }) => (
                    <Box height={200}>
                        <Loader color="white" />
                    </Box>
                )}
            </Modal>
        )
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        create.execute(workorder).then(newWorkorder => {
            onUpdate(newWorkorder)
            history.goBack()
        })
    }

    return (
        <Modal>
            {({ goBack }) => (
                <HStack
                    minWidth={968}
                    alignItems="flex-start"
                    alignSelf="flex-start"
                    bg="#ffffff"
                    css={{
                        boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                    }}
                    {...props}
                >
                    <WorkOrderForm
                        onSubmit={onSubmit}
                        onChange={update}
                        workorder={workorder}
                        users={users}
                        onRemove={() => {}}
                        loading={create.loading}
                        error={create.error}
                        goBack={goBack}
                    />
                </HStack>
            )}
        </Modal>
    )
}

interface ActionPayload {
    actions: {
        description: string
        action: string
    }[]
    workorder: WorkOrder
    onOk: (payload: ActionPayload) => void
    onCancel: () => void
}

export const UpdateWorkOrderModal: React.FC<WorkOrderModalProps> = ({ onUpdate, onDelete, ...props }) => {
    const params = useParams()
    const history = useHistory()
    const id = params.id || '2'
    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const dayIndex = Number(query.dayIndex) || 0

    const data = useAsync(() => timeline.getWorkorder(id), [id], {
        setLoading: state => ({ ...state, loading: true })
    })
    const [actionPayload, setActionPayload] = React.useState<ActionPayload | null>(null)
    const update = useAsyncCallback(timeline.updateWorkorder)
    const remove = useAsyncCallback(timeline.removeWorkorder)
    const usersData = useAsync(() => timeline.getUsers(''), [id], {
        setLoading: state => ({ ...state, loading: true })
    })

    const workorder = data.result
    const users = usersData.result

    const submit = (action?: { description: string; action: string }) => {
        const w = workorder as WorkOrder

        const etag = w.etag
        return update
            .execute(w, etag, action)
            .then(updatedWorkOrder => {
                setActionPayload(null)
                onUpdate(updatedWorkOrder)
                history.goBack()
            })
            .catch(e => {
                if (e.actions) {
                    setActionPayload({
                        actions: e.actions,
                        workorder: w,
                        onOk: (actionPayload: ActionPayload) => {
                            if (actionPayload) {
                                submit(actionPayload.actions[0])
                            }
                        },
                        onCancel: () => {
                            setActionPayload(null)
                        }
                    })
                }
            })
    }

    const submitDateChange = (start: string, end: string, action?: { description: string; action: string }) => {
        if (workorder) {
            const etag = workorder.etag

            update
                .execute(
                    {
                        ...workorder,
                        startdate: start,
                        enddate: end
                    },
                    etag,
                    action
                )
                .then(newWorkorder => {
                    onUpdate(newWorkorder)
                    data.setResult(newWorkorder)
                })
                .catch(e => {
                    if (e.actions) {
                        setActionPayload({
                            actions: e.actions,
                            workorder: workorder,
                            onOk: (actionPayload: ActionPayload) => {
                                if (actionPayload) {
                                    submitDateChange(start, end, actionPayload.actions[0])
                                }
                            },
                            onCancel: () => {
                                setActionPayload(null)
                            }
                        })
                    }
                })
        }
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        submit()
    }

    const onRemove = (event: any, action?: { description: string; action: string }) => {
        event.preventDefault()
        const w = workorder as WorkOrder
        return remove
            .execute(w, action)
            .then(() => {
                setActionPayload(null)
                history.replace('/timeline')
                onDelete(w)
            })
            .catch(e => {
                if (e.actions) {
                    setActionPayload({
                        actions: e.actions,
                        workorder: w,
                        onOk: actionPayload => {
                            if (actionPayload) {
                                onRemove(event, actionPayload.actions[0])
                            }
                        },
                        onCancel: () => {
                            setActionPayload(null)
                        }
                    })
                }
            })
    }

    return (
        <>
            <Modal>
                {({ goBack }) => (
                    <HStack
                        minWidth={968}
                        alignItems="flex-start"
                        alignSelf="flex-start"
                        minHeight={606}
                        bg="#ffffff"
                        css={{
                            boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                        }}
                        {...props}
                    >
                        {(data.loading || usersData.loading) && <Loader />}
                        {data.error && <Text color="red">{data.error.message}</Text>}
                        {workorder && (
                            <WorkOrderForm
                                dayIndex={dayIndex}
                                removeing={remove.loading}
                                loading={update.loading}
                                error={update.error}
                                onSubmit={onSubmit}
                                onDateChange={(start: string, end: string) => {
                                    submitDateChange(start, end)
                                }}
                                onRemove={onRemove}
                                onChange={w => data.setResult(w as WorkOrder)}
                                workorder={workorder}
                                users={users}
                                goBack={goBack}
                            />
                        )}
                    </HStack>
                )}
            </Modal>
            {actionPayload && (
                <Confirm
                    message={actionPayload.actions[0].description}
                    onOk={() => actionPayload.onOk(actionPayload)}
                    onCancel={actionPayload.onCancel}
                />
            )}
        </>
    )
}
