import React, { ChangeEvent } from 'react'
import Box from '../components/Box'
import { useAsyncAbortable, useAsyncCallback, UseAsyncReturn } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import Button from '../components/Button'
import { useParams, useHistory } from 'react-router-dom'
import Input from '../components/Input'
import Text from '../components/Text'
import { VStack, HStack, Spacer } from '../components/Layout'
import Toggle from '../components/Toggle'
import { AdminMachine, AdminMachinePayload } from '../types/admin'

const MachineForm: React.FC<{
    machine: AdminMachine | AdminMachinePayload
    onChange: (e: any) => void
    onDelete?: (e: any) => void
    onSubmit: (e: any) => void
    save: UseAsyncReturn<any, any>
    remove?: UseAsyncReturn<any, any>
}> = ({ machine, onChange, onSubmit, onDelete, save, remove }) => {
    const error = save.error || (remove && remove.error)

    const onTextChange = (e: any) => {
        onChange({
            ...machine,
            [e.target.name]: e.target.value
        })
    }

    return (
        <VStack as="form" spacing={3} width={661} mx="auto" onSubmit={onSubmit}>
            <VStack spacing={1}>
                <Text as="label" htmlFor="name">
                    Name
                </Text>
                <Input my={0} value={machine.name} name="name" onChange={onTextChange} />
            </VStack>
            <VStack spacing={1}>
                <Text as="label" display="block" htmlFor="color">
                    Color
                </Text>
                <Input
                    my={0}
                    name="color"
                    type="color"
                    display="block"
                    height={32}
                    width={64}
                    value={machine.color}
                    onChange={onTextChange}
                />
            </VStack>
            <VStack spacing={1}>
                <Text as="label" htmlFor="work_hours_year">
                    Work hours per year
                </Text>
                <Input name="work_hours_year" my={0} value={machine.work_hours_year} onChange={onTextChange} />
            </VStack>
            <VStack spacing={1}>
                <Text as="label" htmlFor="location">
                    Location
                </Text>
                <Input name="location" my={0} value={machine.location} onChange={onTextChange} />
            </VStack>
            <VStack spacing={1}>
                <Text as="label">Visible on timeline</Text>
                <Toggle
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChange({
                            ...machine,
                            visible_on_timeline: e.target.checked ? true : false
                        })
                    }
                    checked={machine.visible_on_timeline}
                ></Toggle>
            </VStack>
            {error && (
                <Text color="red" my={0}>
                    {error.message}
                </Text>
            )}
            <HStack>
                <Button type="submit" loading={save.loading}>
                    Save
                </Button>
                <Spacer />
                {remove && (
                    <Button variant="secondary" onClick={onDelete} loading={remove.loading}>
                        Delete
                    </Button>
                )}
            </HStack>
        </VStack>
    )
}

const EditMachinePage: React.FunctionComponent = ({ children, ...props }) => {
    const params = useParams()
    const history = useHistory()
    const id = params.id || '1'
    const data = useAsyncAbortable(abortSignal => Admin.getMachine(Number(id), abortSignal), [])
    const save = useAsyncCallback(Admin.updateMachine)
    const remove = useAsyncCallback(Admin.deleteMachine)

    const machine = data.result

    const onChange = (payload: AdminMachine) => {
        if (machine) {
            // @ts-ignore
            data.setResult(payload)
        }
    }

    const onDelete = (e: any) => {
        e.preventDefault()
        if (machine) {
            remove.execute(machine).then(() => {
                history.replace('/admin/machines')
            })
        }
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (machine) {
            save.execute({
                ...machine,
                // @ts-ignore
                work_hours_year: machine.work_hours_year === '' ? null : machine.work_hours_year
            }).then(() => {
                history.replace('/admin/machines')
            })
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {machine && (
                <MachineForm
                    machine={machine}
                    onChange={onChange}
                    onDelete={onDelete}
                    onSubmit={onSubmit}
                    save={save}
                    remove={remove}
                />
            )}
        </Box>
    )
}

export const AddMachinePage: React.FunctionComponent = ({ children, ...props }) => {
    const history = useHistory()
    const create = useAsyncCallback(Admin.createMachine)

    const [machine, setMachine] = React.useState<AdminMachinePayload>({
        name: '',
        visible_on_timeline: false,
        work_hours_year: null,
        location: '',
        orgtype: 'machine',
        color: '#FAFAFA'
    })

    const onChange = (payload: AdminMachinePayload) => {
        setMachine(payload)
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (machine) {
            create
                .execute({
                    ...machine,
                    // @ts-ignore
                    work_hours_year: machine.work_hours_year === '' ? null : machine.work_hours_year
                })
                .then(() => {
                    history.replace('/admin/machines')
                })
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            <MachineForm machine={machine} onChange={onChange} onSubmit={onSubmit} save={create} />
        </Box>
    )
}

export default EditMachinePage
