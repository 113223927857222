import React from 'react'
import ReactSelect from 'react-select'
import appTheme from '../theme'
import { Props } from 'react-select/src/stateManager'
import { SelectComponentsProps } from 'react-select/src/Select'

export type Option = {
    value: number
    label: string
}

interface SelectProps extends Props<Option>, SelectComponentsProps {
    isMulti?: boolean
    onFocus?: () => void
    height?: string
    menuPosition?: 'fixed' | 'absolute'
    disabled?: boolean
    placeholder?: string
    isClearable?: boolean
    isSearchable?: boolean
}

const customStyles = (height: string) => ({
    clearIndicator: (provided: any, state: any) => {
        return {
            ...provided,
            padding: appTheme.space[1],
            svg: {
                width: appTheme.space[3],
                height: appTheme.space[3]
            }
        }
    },
    dropdownIndicator: (provided: any, state: any) => {
        return {
            ...provided,
            padding: appTheme.space[1],
            svg: {
                width: appTheme.space[3],
                height: appTheme.space[3]
            }
        }
    },
    multiValueRemove: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base
    },
    container: (provided: any, state: any) => {
        return {
            ...provided,
            opacity: state.isDisabled ? 0.5 : 1,
            minHeight: height,
            color: '#333333',
            fontSize: '.75rem',
            fontWeight: '700'
        }
    },
    placeholder: (provided: any) => {
        return {
            ...provided
        }
    },
    singleValue: (provided: any) => {
        return {
            ...provided
        }
    },
    option: (provided: any, state: any) => {
        return {
            ...provided,
            padding: '.5rem'
        }
    },
    menu: (provided: any, state: any) => {
        return {
            ...provided,
            marginTop: 1
        }
    },
    groupHeading: (provided: any, state: any) => {
        return {
            ...provided,
            padding: '0.5rem'
        }
    },
    control: (provided: any, state: any) => {
        return {
            ...provided,
            minHeight: height + '!important',
            border: 'none'
        }
    }
})

export const Select: React.FunctionComponent<SelectProps> = ({
    options,
    disabled = false,
    height = '39px',
    ...props
}) => {
    const styles = customStyles(height)
    return (
        <ReactSelect
            className="Select"
            styles={styles}
            isDisabled={disabled}
            theme={(theme: any) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    neutral0: appTheme.colors.offwhite,
                    primary25: appTheme.colors.lightgrey,
                    primary50: appTheme.colors['light-khaki'],
                    primary: appTheme.colors['pale-olive']
                }
            })}
            options={options}
            {...props}
        />
    )
}

Select.displayName = 'Select'
