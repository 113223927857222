// theme.js

// breakpoint values
// any array length works with styled-system

// ['sm', 'md', 'lg', 'xl']
const breakpoints = ['576px', '768px', '992px', '1200px']

const colors = {
    black: '#333333',
    powderblue: '#a6d9de',
    lavender: '#c7cce6',
    indianred: '#e8536e',
    prospekt: 'rgba(51, 51, 51, 0.33)',
    prospekt_bg: 'rgba(212, 212, 212, 0.66)',
    'black-transparent': 'rgba(51, 51, 51, 0.33)',
    'teal-blue': '#02a0b0',
    midgrey: '#c4c4c4',
    lightgrey: '#d4d4d4',
    white: '#fafafa',
    'french-blue': '#4a5aaa',
    'brownish-grey': '#777777',
    offwhite: '#f0f0f0',
    cherry: '#d00030',
    blush: '#f1a38c',
    darkgrey: '#757575',
    'light-khaki': '#ece9a5',
    'pale-olive': '#bed78a',
    'light-yellow-green': '#ceee8b'
}

export type Colors = keyof typeof colors

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
const space = [
    '0rem',
    '.25rem',
    '.5rem',
    '1rem',
    '2rem',
    '4rem',
    '8rem',
    '16rem',
    '32rem'
]

const fontSizes = {
    xxs: '.625rem', // 10px
    xs: '.75rem', // 12px
    sm: '.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem' // 18px
}

export type FontSizes = keyof typeof fontSizes

const lineHeights = {
    none: 1,
    tight: 1.25,
    normal: 1.5,
    loose: 2
}

export type LineHeights = keyof typeof lineHeights

const fontWeights = {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
}

export type FontWeights = keyof typeof fontWeights

const letterSpacings = {
    normal: 'normal',
    caps: '0.25em'
}

// border-radius
const radii = [0, 2, 4, 8]

const borders = [0, '1px solid', '2px solid']

export interface ThemeInterface extends Record<any, any> {
    breakpoints: typeof breakpoints
    colors: typeof colors
    space: typeof space
    fontSizes: typeof fontSizes
    lineHeights: typeof lineHeights
    fontWeights: typeof fontWeights
    letterSpacings: typeof letterSpacings
    radii: typeof radii
    borders: typeof borders
}

const theme: ThemeInterface = {
    breakpoints,
    colors,
    space,
    fontSizes,
    lineHeights,
    fontWeights,
    letterSpacings,
    radii,
    borders
}

export default theme
