import React from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Box from '../components/Box'
import { useAsyncAbortable } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import { TableRow, TableHeader, TableColumn } from '../components/Table'
import Button, { Touchable } from '../components/Button'
import { BigAdd, Menu } from '../components/Icon'
import Link from '../components/Link'
import Text from '../components/Text'
import { AdminMachine, SummaryMachine } from '../types/admin'
import getYear from 'date-fns/get_year'
import { Select } from '../components/Select'

interface PageProps {}


const Machine: React.FC<{machine: SummaryMachine}> = ({machine}) => (
    <TableRow key={machine.id}>
        <TableColumn>
            <Text
                fontWeight="semibold"
                color="black"
                css={{mb: 0}}
            >
                {machine.name}
            </Text>
        </TableColumn>
        <TableColumn>
            {machine.wo_hrs}h
        </TableColumn>
        <TableColumn>{machine.prs_hrs}h</TableColumn>
        <TableColumn>{machine.work_hours_year}h</TableColumn>
        <TableColumn>{machine.usage_perc}%</TableColumn>
    </TableRow>
)

const MachinesList: React.FC<{machines: SummaryMachine[]}> = ({machines}) => {
    return (
        <tbody style={{ width: 661 }}>
            <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Workorder hours</TableHeader>
                <TableHeader>Prospect hours</TableHeader>
                <TableHeader>Expected hours</TableHeader>
                <TableHeader>Utilisation</TableHeader>
            </TableRow>
            {machines.map((machine) => (
                <Machine key={`machine-${machine.id}`}  machine={machine} />
            ))}
        </tbody>
    )
}


const getYearOptions = () => {

    let years = []


    const currentYear = getYear(new Date())


    for (let i = currentYear; i > currentYear - 5; i--) {
        years.push({label: i, value: i})
    }


    return years.reverse()
}

const MachinesSummaryPage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {

    const options = getYearOptions()
    const [year, setYear] = React.useState(options[options.length-1])
    
    const data = useAsyncAbortable(abortSignal => Admin.getSummary(abortSignal, year.value), [year])

    return (
        <Box my={2} {...props} minHeight="100vh">
            <Box width={661} mx="auto">
                <Select isClearable={false} onChange={(x: any) => setYear(x)} value={year as any}  options={options}></Select>
            </Box>
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {data.result && (
                <Box as="table" mt={4} css={{ borderCollapse: 'collapse' }} width={661} mx="auto">
                    <MachinesList
                        machines={data.result}
                    />
                </Box>
            )}
        </Box>
    )
}

export default MachinesSummaryPage
