import React, { ReactElement } from 'react'
import Box, { BoxProps } from './Box'
import 'pikaday/css/pikaday.css'
import Pikaday from 'pikaday'
import { Global, css } from '@emotion/core'
import theme from '../theme'

const globalStyles = css`
    .pika-single {
        z-index: 99999;
    }

    .pika-single.dark-theme {
        margin-top: ${theme.space[2]};
        color: ${theme.colors.black};
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.midgrey};
        border-bottom-color: ${theme.colors.midgrey};
    }

    .dark-theme .pika-label {
        background-color: ${theme.colors.white};
    }

    .dark-theme .pika-table th {
        color: ${theme.colors.midgrey};
    }

    .dark-theme .pika-button {
        color: ${theme.colors.darkgrey};
        background: ${theme.colors.white};
        font-weight: ${theme.fontWeights.semibold};
        text-align: center;
    }

    .dark-theme .pika-week {
        color: #999;
    }

    .dark-theme .is-selected .pika-button {
        color: ${theme.colors.black};
        background: ${theme.colors['pale-olive']};
        box-shadow: none;
    }

    .dark-theme .pika-button:hover {
        color: ${theme.colors.black} !important;
        background: ${theme.colors.midgrey} !important;
    }
`

interface DatepickerProps extends BoxProps {
    onSelect?: (d: Date) => void
}

const Datepicker: React.FC<DatepickerProps> = ({ children, onSelect, ...props }) => {
    const pickerRef = React.useRef<Pikaday>()
    const inputRef = React.createRef<HTMLInputElement>()
    const triggerRef = React.createRef<HTMLInputElement>()

    React.useLayoutEffect(() => {
        if (inputRef.current) {
            if (triggerRef.current) {
                pickerRef.current = new Pikaday({
                    field: inputRef.current,
                    trigger: triggerRef.current,
                    format: 'YYYY-MM-DD',
                    keyboardInput: false,
                    theme: 'dark-theme',
                    onSelect: onSelect
                })
            } else {
                pickerRef.current = new Pikaday({
                    field: inputRef.current,
                    format: 'YYYY-MM-DD',
                    keyboardInput: false,
                    theme: 'dark-theme',
                    onSelect: onSelect
                })
            }
        }

        return () => {
            if (pickerRef.current) {
                pickerRef.current.destroy()
            }
        }
    }, [inputRef, triggerRef, onSelect])

    const child = React.Children.count(children) === 1 && React.Children.only(children)

    return (
        <>
            <Global styles={globalStyles} />
            <Box
                ref={inputRef}
                as="input"
                p={2}
                m={0}
                readOnly={true}
                lineHeight="normal"
                border="1px solid"
                bg="offwhite"
                borderColor="offwhite"
                fontSize="xs"
                fontWeight="semibold"
                color="black"
                css={{
                    '&[disabled]': {
                        opacity: 0.6
                    },
                    display: child ? 'none' : 'block',
                    '&:hover': {
                        outline: 'none',
                        borderColor: 'grey-darkest'
                    },

                    '&:focus': {
                        outline: 'none',
                        borderColor: 'grey-darkest'
                    },

                    transition: '250ms border-color ease'
                }}
                {...props}
            ></Box>
            {child && React.cloneElement(child as ReactElement, { ref: triggerRef })}
        </>
    )
}

export default Datepicker
