import React, { SyntheticEvent } from 'react'
import Box, { BoxProps } from './Box'
import { HStack } from './Layout'
import Text from './Text'

type ToggleProps = {
    name?: string
    htmlFor?: string
    onChange?: React.EventHandler<SyntheticEvent<HTMLInputElement>>
    checked?: boolean
} & BoxProps

const Toggle: React.FunctionComponent<ToggleProps> = ({ name, onChange, checked, children, ...props }) => {
    return (
        <HStack
            as="label"
            spacing={2}
            justifyContent="baseline"
            htmlFor={name}
            mb={3}
            css={{
                userSelect: 'none',
                input: { display: 'none' },
                p: { userSelect: 'none' },
                'input:checked + .toggle__bg': { bg: 'pale-olive' },
                'input:checked + .toggle__bg .toggle__thumb': {
                    transform: 'translateX(1rem)'
                }
            }}
            {...props}
        >
            <input type="checkbox" name={name} onChange={onChange} checked={checked} />

            <Box
                borderRadius="1.33333333rem"
                width="2.5rem"
                height="1.5rem"
                bg="midgrey"
                p={1}
                className="toggle__bg"
                css={{
                    transition: '.25s background ease',
                    cursor: 'pointer'
                }}
            >
                <Box
                    bg="white"
                    borderRadius="1rem"
                    width="1rem"
                    height="1rem"
                    className="toggle__thumb"
                    css={{
                        transition: '.25s transform ease'
                    }}
                ></Box>
            </Box>

            <Text mb={0} fontSize="sm" lineHeight="1rem" letterSpacing="-0.07px" fontWeight="semibold" color="black">
                {children}
            </Text>
        </HStack>
    )
}

export default Toggle
