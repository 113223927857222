import React from 'react'
import Modal from './Modal'
import Box, { BoxProps } from './Box'
import Button from './Button'

interface ConfirmProps extends BoxProps {
    onClose: () => {}
}

const MachineDialog: React.FC<ConfirmProps> = ({ onClose, css, children, ...props }) => (
    <Modal bg="transparent" onClose={onClose} css={css as any}>
        {() => (
            <Box
                alignSelf="flex-start"
                css={{
                    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                }}
                minWidth={210}
                p={0}
                bg="white"
                position="relative"
                {...props}
            >
                <Button
                    position="absolute"
                    top={-16}
                    right={-16}
                    width={32}
                    height={32}
                    borderRadius="100%"
                    bg="white"
                    variant="secondary"
                    border="none"
                    fontSize="base"
                    p={0}
                    m={0}
                    zIndex={1}
                    onClick={onClose}
                    css={{
                        outline: 'none',
                        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16)'
                    }}
                >
                    ✕
                </Button>
                {children}
            </Box>
        )}
    </Modal>
)

export default MachineDialog
