import React from 'react'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import Box, { BoxProps } from '../components/Box'
import { useAsyncAbortable, useAsyncCallback } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import { TableRow, TableHeader, TableColumn } from '../components/Table'
import { Touchable } from '../components/Button'
import Input from '../components/Input'
import Toggle from '../components/Toggle'
import { BigAdd } from '../components/Icon'
import { AdminTeam } from '../types/admin'
import { DragHandle } from './MachinesPage'
import { HStack } from '../components/Layout'

interface PageProps {}

export const ControlledInput: React.FC<BoxProps> = ({ value, onChange, ...props }) => {
    const [boxedValue, setBoxedValue] = React.useState(value)

    React.useEffect(() => {
        setBoxedValue(value)
    }, [value])

    return (
        <Input
            value={boxedValue}
            onChange={(e: any) => setBoxedValue(e.target.value)}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    onChange && onChange(boxedValue)
                }
            }}
            onBlur={() => {
                onChange && onChange(boxedValue)
            }}
            {...props}
        />
    )
}

const TeamRow = SortableElement(
    ({
        team,
        onUpdate,
        onRemove
    }: {
        team: AdminTeam
        onUpdate: (team: AdminTeam) => void
        onRemove: (team: AdminTeam) => void
    }) => {
        const remove = useAsyncCallback(Admin.deleteTeam)
        const update = useAsyncCallback(Admin.updateTeam)
        const create = useAsyncCallback(Admin.createTeam)

        const onTeamChange = (t: AdminTeam) => {
            if (t.id === -1) {
                const { id, ...payload } = t
                create.execute(payload as AdminTeam).then(updatedTeam => {
                    onUpdate(updatedTeam)
                })
            } else {
                update.execute(t).then(updatedTeam => {
                    onUpdate(updatedTeam)
                })
            }
        }

        return (
            <TableRow key={team.id}>
                <TableColumn>
                    <HStack>
                        <DragHandle />
                        <ControlledInput
                            disabled={update.loading || create.loading}
                            mb={0}
                            value={team.name}
                            onChange={(value: string) => {
                                onTeamChange({ ...team, name: value })
                            }}
                        />
                    </HStack>
                </TableColumn>
                <TableColumn>
                    <Toggle
                        my={0}
                        checked={team.use_travel_time}
                        onChange={(e: any) => {
                            onTeamChange({ ...team, use_travel_time: e.target.checked })
                        }}
                    ></Toggle>
                </TableColumn>
                <TableColumn>
                    <Toggle
                        my={0}
                        checked={team.visible_on_timeline}
                        onChange={(e: any) => {
                            onTeamChange({ ...team, visible_on_timeline: e.target.checked })
                        }}
                    ></Toggle>
                </TableColumn>
                <TableColumn textAlign="right">
                    <Touchable
                        loading={remove.loading}
                        css={{ textTransform: 'uppercase' }}
                        fontWeight="semibold"
                        onClick={() => {
                            if (team.id !== -1) {
                                remove.execute(team).then(() => {
                                    onRemove(team)
                                })
                            } else {
                                onRemove(team)
                            }
                        }}
                    >
                        {remove.loading ? '' : 'Delete'}
                    </Touchable>
                </TableColumn>
            </TableRow>
        )
    }
)

const TeamsList = SortableContainer(
    ({
        teams,
        onTeamUpdate,
        onRemove,
        onAdd
    }: {
        teams: AdminTeam[]
        onRemove: (index: number) => void
        onTeamUpdate: (t: AdminTeam, index: number) => void
        onAdd: () => void
    }) => {
        return (
            <tbody>
                <TableRow>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Travel time</TableHeader>
                    <TableHeader>Visible on timeline</TableHeader>
                    <TableHeader textAlign="right">
                        <Touchable onClick={onAdd}>
                            <BigAdd></BigAdd>
                        </Touchable>
                    </TableHeader>
                </TableRow>
                {teams.map((team, teamIndex) => (
                    <TeamRow
                        index={teamIndex}
                        onUpdate={(t: AdminTeam) => onTeamUpdate(t, teamIndex)}
                        onRemove={(t: AdminTeam) => {}}
                        team={team}
                        key={team.id}
                    />
                ))}
            </tbody>
        )
    }
)

const TeamsPage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {
    const data = useAsyncAbortable(abortSignal => Admin.getTeams(abortSignal), [])

    const onTeamUpdate = (t: AdminTeam, index: number) => {
        if (data.result) {
            const newTeams = [...data.result]
            newTeams[index] = t
            data.setResult(newTeams)
        }
    }

    const onAdd = () => {
        if (data.result) {
            data.setResult([
                ...data.result,
                {
                    id: -1,
                    name: '',
                    order: data.result.length,
                    orgtype: 'team',
                    shiftperiod_id: null,
                    visible_on_timeline: false,
                    use_travel_time: false
                }
            ])
        }
    }

    const onRemove = (index: number) => {
        if (data.result) {
            const newTeams = [...data.result]
            newTeams.splice(index, 1)
            data.setResult(newTeams)
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        if (data.result) {
            const newTeams = arrayMove(data.result, oldIndex, newIndex).map((x, index) => {
                x.order = index
                return x
            })

            data.setResult(newTeams)
            Admin.updateTeams(newTeams)
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {data.result && (
                <Box as="table" mt={4} css={{ borderCollapse: 'collapse' }} width={661} mx="auto">
                    <TeamsList
                        teams={data.result}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onTeamUpdate={onTeamUpdate}
                        lockAxis="y"
                        onSortEnd={onSortEnd}
                        helperClass="sorting-row"
                        useDragHandle
                    />
                </Box>
            )}
        </Box>
    )
}

export default TeamsPage
