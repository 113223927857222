import React from 'react'
import Box, { BoxProps } from './Box'

const Badge: React.FC<BoxProps> = props => (
    <Box
        bg="cherry"
        {...props}
        width="1rem"
        height="1rem"
        borderRadius="100%"
        position="absolute"
        textAlign="center"
        right="-.25rem"
        top="-.25rem"
        color="white"
        lineHeight="1rem"
        fontSize="xxs"
    />
)

export default Badge
