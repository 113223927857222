import React, { FormEvent } from 'react'
import qs from 'qs'
import Box, { BoxProps } from '../components/Box'
import { HStack, Divider, VStack, Spacer } from '../components/Layout'
import Text from '../components/Text'
import format from 'date-fns/format'
import { useAsync } from '../hooks/useAsync'
import { timeline } from '../api'
import Loader from '../components/Loader'
import Modal from '../components/Modal'
import { User } from '../types/users'
import { useHistory, useParams } from 'react-router-dom'
import { Translated } from '../context/LocaleContext'

interface WorkOrderModalProps extends BoxProps {}

interface WorkOrderProps {
    shiftId?: number
    workorder: WorkOrder
}

const WorkOrder: React.FC<WorkOrderProps> = ({ shiftId, workorder }) => {
    return (
        <>
            <Box flex="1" p={4}>
                <VStack>
                    <Text fontWeight="semibold">{workorder.name}</Text>
                    <HStack spacing={3}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" width={100} fontWeight="semibold">
                            {Translated('workperiod')}
                        </Text>
                        <Text fontSize="xs" fontWeight="semibold" mb={0}>{`${format(
                            workorder.startdate,
                            'YYYY-MM-DD'
                        )} – ${format(workorder.enddate, 'YYYY-MM-DD')}`}</Text>
                    </HStack>
                    <HStack spacing={3}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                            {Translated('postcode')}
                        </Text>
                        <Text fontSize="xs" mb={0} fontWeight="semibold">
                            {workorder.postcode}
                        </Text>
                    </HStack>
                    <HStack spacing={3}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                            {Translated('address')}
                        </Text>
                        <Text fontSize="xs" mb={0} fontWeight="semibold">
                            {workorder.address}
                        </Text>
                    </HStack>
                    <HStack spacing={3}>
                        <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                            {Translated('project_manager')}
                        </Text>
                        <Text fontSize="xs" mb={0} fontWeight="semibold">
                            {workorder.projectleader_name}
                        </Text>
                    </HStack>
                    <VStack mt={3}>
                        <HStack spacing={3}>
                            <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                                {Translated('number_of_operators')}
                            </Text>
                            <Text fontSize="xs" mb={0} fontWeight="semibold">
                                {workorder.operators}
                            </Text>
                        </HStack>
                        <HStack spacing={3} alignItems="flex-start">
                            <Text color="brownish-grey" mb={0} fontSize="xs" fontWeight="semibold" width={100}>
                                {Translated('notes')}
                            </Text>
                            <Text fontSize="xs" mb={0} fontWeight="semibold">
                                {workorder.notes}
                            </Text>
                        </HStack>
                    </VStack>
                    <Spacer />
                </VStack>
            </Box>
            <Divider />
            <Box flex="1" py={4}>
                <VStack spacing={3}>
                    {workorder.days.map(day => {
                        return (
                            <Box>
                                <Text fontSize="xs" mb={0} fontWeight="semibold">
                                    {format(day.date, 'dddd DD/M')}
                                </Text>
                                {day.shifts.map(s => (
                                    <HStack
                                        alignItems="flex-start"
                                        css={{
                                            '> .Text': {
                                                fontWeight: 'semibold',
                                                fontSize: 'xs',
                                                mb: 0
                                            }
                                        }}
                                    >
                                        <Text css={{ flexShrink: 0 }}>{s.starttime}</Text>
                                        <Text css={{ flexShrink: 0 }}>
                                            {s.hours} {Translated('hours')}
                                        </Text>
                                        <Text>
                                            {s.people.map(x => x.name).join(', ')}
                                            <Text as="span" fontSize="xs" color="brownish-grey" display="block">
                                                {s.notes}
                                            </Text>
                                        </Text>
                                    </HStack>
                                ))}
                            </Box>
                        )
                    })}
                </VStack>
            </Box>
        </>
    )
}

export const OperatorWorkOrderModal: React.FC<WorkOrderModalProps> = ({ ...props }) => {
    const params = useParams()
    const history = useHistory()
    const id = params.id || '2'
    const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    const shift_id = Number(query.shift_id)

    const data = useAsync(() => timeline.getShiftWorkorder(id,shift_id.toString()), [id,shift_id], {
        setLoading: state => ({ ...state, loading: true })
    })
    const workorder = data.result

    return (
        <>
            <Modal>
                {({ goBack }) => (
                    <HStack
                        minWidth={968}
                        alignItems="flex-start"
                        alignSelf="flex-start"
                        minHeight={606}
                        bg="#ffffff"
                        css={{
                            boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                        }}
                        {...props}
                    >
                        {data.loading && <Loader />}
                        {data.error && <Text>{data.error.message}</Text>}
                        {workorder && <WorkOrder shiftId={shift_id} workorder={workorder} />}
                    </HStack>
                )}
            </Modal>
        </>
    )
}
