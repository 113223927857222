import React from 'react'
import Box, { BoxProps } from './Box'

export type TextProps = {
    caps?: boolean
} & BoxProps

type Text = React.FunctionComponent<TextProps>

const Text: Text = React.forwardRef<any, TextProps>(({ css, caps, ...props }, ref) => (
    <Box
        as="p"
        ref={ref}
        className="Text"
        mb={3}
        fontWeight="normal"
        fontSize="sm"
        lineHeight="normal"
        css={{
            userSelect: 'text',
            ...(caps ? { textTransform: 'uppercase' } : {}),
            ...css
        }}
        {...props}
    />
))

Text.displayName = 'Text'

export default Text
