import React from 'react'
import Modal from './Modal'
import Box, { BoxProps } from './Box'
import Text from './Text'
import { HStack } from './Layout'
import Button from './Button'
import { Translated } from '../context/LocaleContext'

interface ConfirmProps extends BoxProps {
    onOk: () => void
    onCancel: () => void
    message: string
    loading?: boolean
}

const Confirm: React.FC<ConfirmProps> = ({ onOk, onCancel, message, loading, ...props }) => (
    <Modal bg="transparent" onClose={onCancel}>
        {() => (
            <Box
                alignSelf="flex-start"
                css={{
                    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2)'
                }}
                width={612}
                p={3}
                bg="white"
                position="relative"
                {...props}
            >
                <Button
                    position="absolute"
                    top={-16}
                    right={-16}
                    width={32}
                    height={32}
                    borderRadius="100%"
                    bg="white"
                    variant="secondary"
                    border="none"
                    fontSize="base"
                    p={0}
                    m={0}
                    onClick={onCancel}
                    css={{
                        outline: 'none',
                        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16)'
                    }}
                >
                    ✕
                </Button>
                <Text fontWeight="semibold" fontSize="sm" mt={2}>
                    {message}
                </Text>
                <HStack spacing={2}>
                    <Button loading={loading} onClick={onOk}>
                        Ok
                    </Button>
                    <Button onClick={onCancel} variant="tertiary">
                        {Translated('cancel')}
                    </Button>
                </HStack>
            </Box>
        )}
    </Modal>
)

export default Confirm
