import React, { ChangeEvent } from 'react'
import Box from '../components/Box'
import { useAsyncCallback, UseAsyncReturn, useAsync } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import Button from '../components/Button'
import { useParams, useHistory } from 'react-router-dom'
import Input from '../components/Input'
import Text from '../components/Text'
import { VStack, HStack, Spacer } from '../components/Layout'
import { AdminLegalEntity } from '../types/admin'
import Datepicker from '../components/Datepicker'
import format from 'date-fns/format'

const LegalEntityForm: React.FC<{
    entity: Partial<AdminLegalEntity>
    onChange: (e: any) => void
    onDelete?: (e: any) => void
    onSubmit: (e: any) => void
    save: UseAsyncReturn<any, any>
    remove?: UseAsyncReturn<any, any>
}> = ({ entity, onChange, onSubmit, onDelete, save, remove }) => {
    const error = save.error || (remove && remove.error)

    const operatorAccessOptions = [
        {
            label: 'Workorder and Workperiod',
            value: 'Workorder and Workperiod'
        },
        {
            label: 'Workorder',
            value: 'Workorder'
        },
        {
            label: 'Workperiod',
            value: 'Workperiod'
        }
    ]

    return (
        <VStack as="form" spacing={3} width={661} mx="auto" onSubmit={onSubmit}>
            <HStack>
                <VStack spacing={1}>
                    <Text as="label" htmlFor="name">
                        Name
                    </Text>
                    <Input
                        onChange={(e: any) => {
                            onChange({
                                ...entity,
                                name: e.target.value
                            })
                        }}
                        my={0}
                        value={entity.name}
                        name="name"
                    />
                </VStack>
                <VStack spacing={1}>
                    <Text as="label" htmlFor="location">
                        Timezone
                    </Text>
                    <Input
                        name="location"
                        my={0}
                        value={entity.tz}
                        onChange={(e: any) => {
                            onChange({
                                ...entity,
                                tz: e.target.value
                            })
                        }}
                    />
                </VStack>
            </HStack>
            <VStack spacing={1}>
                <Text as="label">Week 1 starts at</Text>
                <Datepicker
                    value={entity.weekyearstart}
                    onSelect={(d: Date) => {
                        onChange({
                            ...entity,
                            weekyearstart: format(d, 'YYYY-MM-DD')
                        })
                    }}
                />
            </VStack>
            <VStack spacing={1}>
                <Text as="label">Operator access</Text>
                <Input.NativeSelect
                    value={entity.operator_access}
                    onChange={(e: any) => {
                        onChange({
                            ...entity,
                            operator_access: e.target.value
                        })
                    }}
                    name="operator_access"
                >
                    {operatorAccessOptions.map(x => (
                        <option value={x.value}>{x.label}</option>
                    ))}
                </Input.NativeSelect>
            </VStack>
            <VStack>
                <Text as="label">Entity issue rules</Text>
                <Box
                    css={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '> *': {
                            mr: 3,
                            mb: 3,
                            flexBasis: 'calc(50% - 1rem)'
                        }
                    }}
                >
                    {Object.keys(entity_types).map(x => {
                        type keys = keyof AdminLegalEntity['monitorissues']

                        const checked = entity.monitorissues && entity.monitorissues[x as keys]
                        return (
                            <Input.Checkbox
                                onChange={(e: any) => {
                                    onChange({
                                        ...entity,
                                        monitorissues: {
                                            ...entity.monitorissues,
                                            [x]: e.target.checked
                                        }
                                    })
                                }}
                                checked={checked}
                            >
                                {entity_types[x as keys]}
                            </Input.Checkbox>
                        )
                    })}
                </Box>
            </VStack>
            {error && (
                <Text color="red" my={0}>
                    {error.message}
                </Text>
            )}
            <HStack>
                <Button type="submit" loading={save.loading}>
                    Save
                </Button>
                <Spacer />
                {remove && (
                    <Button variant="secondary" onClick={onDelete} loading={remove.loading}>
                        Delete
                    </Button>
                )}
            </HStack>
        </VStack>
    )
}

type keys = keyof AdminLegalEntity['monitorissues']

const entity_types: {
    [k in keys]: string
} = {
    days_of_week_service_exceeded: 'Total shift length over a 7 day period excess 72 hours',
    hours_of_service_exceeded: 'Shift is less than 13h after the previous',
    operator_outside_period_allowed: "Allow operator to be assigned if shift ends on the same day as assigned workperiod",
    operator_double_booked: 'Operator is double booked',
    operator_unavailable_for_shift: 'Operator needs to have a work period during shift',
    shift_operators_working: 'Shift needs 2 operators assigned for every 12h of shift duration',
    shift_to_close_after_travel: 'Operator needs to rest 12h after traveling, before starting a shift',
    shift_too_long: 'Shift is longer than 10h',
    two_weeks_work_exceeded: 'Operator is booked on more than 13 shifts in a 14 day period',
    user_logs_worked_shift: 'Operator needs to log worked time',
    user_travel_needed: 'Operator needs to log travel duration'
}

const EditLegalEntityPage: React.FunctionComponent = ({ children, ...props }) => {
    const params = useParams()
    const history = useHistory()
    const id = params.id || '1'
    const data = useAsync(() => Admin.getLegalEntity(Number(id)), [id])
    const save = useAsyncCallback(Admin.updateLegalEntity)
    const remove = useAsyncCallback(Admin.removeLegalEntity)

    const entity = data.result

    const onChange = (payload: AdminLegalEntity) => {
        if (entity) {
            data.setResult(payload)
        }
    }

    const onDelete = (e: any) => {
        e.preventDefault()
        if (entity) {
            remove.execute(entity).then(() => {
                history.replace('/admin/legal')
            })
        }
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (entity) {
            save.execute(entity).then(() => {
                history.replace('/admin/legal')
            })
        }
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {entity && (
                <LegalEntityForm
                    entity={entity}
                    onChange={onChange}
                    onDelete={onDelete}
                    onSubmit={onSubmit}
                    save={save}
                    // remove={remove}
                />
            )}
        </Box>
    )
}

export const AddLegalEntityPage: React.FunctionComponent = ({ children, ...props }) => {
    const history = useHistory()
    const create = useAsyncCallback(Admin.createLegalEntity)

    const [entity, setEntity] = React.useState<Partial<AdminLegalEntity>>({
        name: '',
        tz: ''
    })

    const onChange = (payload: Partial<AdminLegalEntity>) => {
        setEntity(payload)
    }

    const onSubmit = (e: any) => {
        e.preventDefault()
        create.execute(entity).then(() => {
            history.replace('/admin/legal')
        })
    }

    return (
        <Box my={2} {...props} minHeight="100vh">
            <LegalEntityForm entity={entity} onChange={onChange} onSubmit={onSubmit} save={create} />
        </Box>
    )
}

export default EditLegalEntityPage
