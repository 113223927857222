import { useState, useEffect } from 'react'

const getSize = () => [window.innerWidth, window.innerHeight]

// Hook
export function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}
