import React from 'react'
import Box from '../components/Box'
import Header from '../components/Header'
import Text from '../components/Text'
import { HStack, Container } from '../components/Layout'
import { NavLink as RouterNavLink } from '../components/Link'

interface PageProps {}

interface LinkProps {
    to: string
    exact?: boolean
}

const Link: React.FC<LinkProps> = props => (
    <Box
        as={RouterNavLink}
        color="black"
        fontSize="sm"
        fontWeight="semibold"
        borderBottom={2}
        pb={1}
        activeClassname="active"
        css={{
            textDecoration: 'none',
            borderBottomColor: 'transparent',
            '&.active': {
                borderBottomColor: 'pale-olive'
            }
        }}
        {...props}
    />
)

const AdminPage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {
    return (
        <Box {...props}>
            <Box
                bg="#ffffff"
                pb={2}
                css={{
                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)'
                }}
                position="sticky"
                zIndex={1}
                top={0}
            >
                <Header links={[{ path: '/#/timeline', label: 'Timeline' }]}>
                    <Text m={0} lineHeight="32px" fontWeight="semibold" fontSize="base">
                        System admin
                    </Text>
                </Header>
                <Container mt={4}>
                    <HStack ml={222} spacing={3}>
                        <Link to="/admin/users">Users</Link>
                        <Link to="/admin/machines">Machines</Link>
                        <Link to="/admin/machines-summary">Machine summary</Link>
                        <Link to="/admin/teams">Teams</Link>
                        <Link to="/admin/legal">Legal entities</Link>
                        <Link to="/admin/absence">Absence</Link>
                    </HStack>
                </Container>
            </Box>
            <Box p={3} mt={2} bg="white">
                <Container>{children}</Container>
            </Box>
        </Box>
    )
}

export default AdminPage
