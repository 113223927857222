import * as React from 'react'
import styled from '../styled'
import shouldForwardProp from '@styled-system/should-forward-prop'
import styledCss from '@styled-system/css'
import { space, color, layout, typography, flexbox, grid, position, border, system, compose } from 'styled-system'
import { SystemProps } from '../styled-system'

const borderSidesColor = system({
    borderLeftColor: {
        property: 'borderLeftColor',
        scale: 'colors'
    },
    borderRightColor: {
        property: 'borderRightColor',
        scale: 'colors'
    },
    borderBottomColor: {
        property: 'borderBottomColor',
        scale: 'colors'
    },
    borderTopColor: {
        property: 'borderTopColor',
        scale: 'colors'
    }
})

export const styledProps = compose(
    space,
    layout,
    color,
    flexbox,
    position,
    border,
    grid,
    typography,
    borderSidesColor
)

const cssInterpolation = ({ css }: { css: any }) => {
    const output = styledCss(css)
    return output
}

const WHITE_LIST_PROPS = [
    'animation',
    'animateFill',
    'placement',
    'distance',
    'draggable',
    'trigger',
    'hideOnClick',
    'exact',
    'delay',
    'interactive'
]

export interface BoxProps extends SystemProps, React.PropsWithoutRef<any> {}

const Box = styled('div', {
    shouldForwardProp: (prop: string) => shouldForwardProp(prop) || WHITE_LIST_PROPS.includes(prop)
})<BoxProps>(styledProps, cssInterpolation)

Box.displayName = 'Box'

export default Box
