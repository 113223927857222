import * as React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import './Modal.css'
import Box, { BoxProps } from './Box'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { TimelineContext } from '../pages/TimelinePage'

interface ChildrenPayload {
    goBack: () => void
}

interface ModalProps extends BoxProps {
    path?: string
    onClose?: (status: boolean) => void
    children: (p: ChildrenPayload) => React.ReactElement
}

const Modal: React.FunctionComponent<ModalProps> = ({
    css,
    initialFocusRef,
    children,
    onClose: onParentClose,
    ...props
}) => {
    const history = useHistory()
    const { date } = React.useContext(TimelineContext)

    const goBack = () => {
        if (date) {
            history.push('/timeline?date=' + format(date, 'YYYY-MM-DD'))
        } else {
            history.push('/timeline')
        }
    }

    const onClose = () => {
        if (onParentClose) {
            onParentClose(false)
        } else {
            goBack()
        }
    }

    return (
        <DialogOverlay initialFocusRef={initialFocusRef} isOpen={true} onDismiss={onClose}>
            <Box
                as={DialogContent}
                css={{
                    '&[data-reach-dialog-content]': {
                        bg: 'none',
                        height: 'auto',
                        width: 'auto',
                        p: 0,
                        flexShrink: 0,
                        ...css
                    }
                }}
            >
                {children({ goBack })}
            </Box>
        </DialogOverlay>
    )
}

export default Modal
