import React from 'react'
import Box, { BoxProps } from './Box'

export const TableHeader: React.FC<BoxProps> = props => (
    <Box
        as="th"
        textAlign="left"
        color="brownish-grey"
        fontWeight="semibold"
        fontSize="xs"
        p={3}
        css={{
            textTransform: 'uppercase'
        }}
        {...props}
    ></Box>
)

export const TableColumn: React.FC<BoxProps> = props => (
    <Box as="td" p={3} fontSize="sm" color="black" fontWeight="semibold" css={{}} {...props}></Box>
)

export const TableRow: React.FC<BoxProps> = props => <Box as="tr" borderBottom={1} borderColor="midgrey" {...props} />
