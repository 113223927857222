import { login as postLogin, fetchCurrentUser, updateCurrentUser } from '../api'
import { LoginUser } from '../types/auth'
import Cookies from 'js-cookie'
import { assign, Machine } from 'xstate'
import { useMachine } from '@xstate/react'

// @ts-ignore
window.Cookies = Cookies

const userMachine = Machine<{ user: LoginUser | null; error?: Error }>({
    id: 'login',
    initial: Cookies.get('session_token') ? 'validating' : 'loggedOut',
    context: {
        user: null
    },
    states: {
        logout: {
            invoke: {
                id: 'logout',
                src: (context, event) => callback => {
                    localStorage.clear()
                    Cookies.remove('session_token')
                    callback('LOGOUT')
                }
            },
            on: {
                LOGOUT: 'loggedOut'
            }
        },

        login: {
            invoke: {
                id: 'loginUser',
                src: (context, event) => postLogin(event.username, event.password),
                onDone: {
                    target: 'loggedIn',
                    actions: assign({
                        user: (context, event) => {
                            Cookies.set('session_token', event.data.session_token, { expires: 30 })
                            return event.data
                        }
                    })
                },
                onError: {
                    target: 'loggedOut',
                    actions: assign({ error: (context, event) => event.data })
                }
            }
        },

        validating: {
            invoke: {
                id: 'validateUser',
                src: (context, event) => fetchCurrentUser(),
                onDone: {
                    target: 'loggedIn',
                    actions: assign({
                        user: (context, event) => {
                            return event.data
                        }
                    })
                },
                onError: {
                    target: 'loggedOut',
                    actions: assign({
                        user: (context, event) => {
                            return null
                        }
                    })
                }
            }
        },
        loggedIn: {
            on: {
                UPDATE: {
                    actions: assign({
                        user: (context, event) => {
                            return { ...context.user, ...event.data }
                        }
                    })
                },
                LOGOUT: 'logout'
            }
        },
        loggedOut: {
            on: {
                LOGIN: 'login'
            }
        }
    }
})

const useAuth = () => {
    const [state, send] = useMachine(userMachine)
    const { context, matches } = state

    const updateLegalEntity = (legal_entity_id: number) => {
        return updateCurrentUser({
            legal_entity_id
        }).then(updatedUser => {
            send('UPDATE', updatedUser)
        })
    }

    return {
        user: context.user,
        error: context.error,
        matches,
        updateLegalEntity,
        login: (username: string, password: string) => send('LOGIN', { username, password }),
        logout: () => send('LOGOUT')
    }
}

export default useAuth
