import React from 'react'
import Box from '../components/Box'
import { useAsyncAbortable } from '../hooks/useAsync'
import { Admin } from '../api'
import Loader from '../components/Loader'
import { TableRow, TableHeader, TableColumn } from '../components/Table'
import { Touchable } from '../components/Button'
import { BigAdd } from '../components/Icon'
import Link from '../components/Link'
import Text from '../components/Text'
import { AdminLegalEntity } from '../types/admin'

interface PageProps {}

const LegalEntity: React.FC<{ entity: AdminLegalEntity }> = ({ entity }) => (
    <TableRow key={entity.id}>
        <TableColumn>
            <Text
                as={Link}
                fontWeight="semibold"
                css={{
                    textDecoration: 'none'
                }}
                color="black"
                to={'/admin/legal/' + entity.id}
            >
                {entity.name}
            </Text>
        </TableColumn>
        <TableColumn>{entity.tz}</TableColumn>
        <TableColumn></TableColumn>
    </TableRow>
)

const LegalEntities: React.FC<{ entities: AdminLegalEntity[] }> = ({ entities }) => {
    return (
        <tbody style={{ width: 661 }}>
            <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Timezone</TableHeader>
                <TableHeader textAlign="right">
                    <Box as={Link} to="/admin/legal/add">
                        <Touchable>
                            <BigAdd />
                        </Touchable>
                    </Box>
                </TableHeader>
            </TableRow>
            {entities.map((entity, index) => (
                <LegalEntity key={entity.id} entity={entity} />
            ))}
        </tbody>
    )
}

const LegalEntitiesPage: React.FunctionComponent<PageProps> = ({ children, ...props }) => {
    const data = useAsyncAbortable(abortSignal => Admin.getLegalEntities(abortSignal), [])

    return (
        <Box my={2} {...props} minHeight="100vh">
            {data.loading && (
                <Box height={300}>
                    <Loader />
                </Box>
            )}
            {data.result && (
                <Box as="table" mt={4} css={{ borderCollapse: 'collapse' }} width={661} mx="auto">
                    <LegalEntities entities={data.result} />
                </Box>
            )}
        </Box>
    )
}

export default LegalEntitiesPage
