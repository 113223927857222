import React from 'react'
import {
    Menu as ReachMenu,
    MenuList as ReachMenuList,
    MenuButton as ReachMenuButton,
    MenuItem as ReachMenuItem,
    MenuLink as ReachMenuLink,
    MenuListProps,
    MenuButtonProps,
    MenuItemProps,
    MenuLinkProps
} from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import Box, { BoxProps } from './Box'
import Button from './Button'

type Diff<T, U> = T extends U ? never : T

type Overwrite<T, U> = Pick<T, Diff<keyof T, keyof U>> & U

export const Menu = ReachMenu

interface CustomMenuListProps extends Overwrite<MenuListProps, BoxProps> {
    as?: any
}

export const MenuList: React.FC<CustomMenuListProps> = props => (
    <Box
        as={ReachMenuList}
        css={{
            bg: 'white',
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)',
            mt: 1,
            p: 0,
            '[data-reach-menu-item]': {
                minHeight: '2rem',
                lineHeight: '1.6',
                p: 2,
                bg: 'white',
                color: 'black',
                '&:not(:last-child)': {
                    borderBottom: 1,
                    borderBottomColor: 'midgrey'
                },
                '&[data-selected]': {
                    color: 'black',
                    bg: 'white'
                },
                '&:hover': {
                    color: 'black',
                    bg: 'offwhite'
                }
            }
        }}
        {...props}
    />
)

interface CustomMenuButtonProps extends Overwrite<MenuButtonProps, BoxProps> {
    as?: any
}

export const MenuButton: React.FC<CustomMenuButtonProps> = props => (
    // @ts-ignore
    <Button variant="touchable" as={ReachMenuButton} css={{ bg: '#fff', border: 0, outline: 0 }} {...props} />
)

interface CustomMenuItemProps extends Omit<MenuItemProps, 'color'>, BoxProps {
    as?: any
}

export const MenuItem: React.FC<CustomMenuItemProps> = props => (
    <ReachMenuItem css={{ textAlign: 'left' }} as={Box} {...props} />
)

interface CustomMenuLinkProps extends Omit<MenuLinkProps<HTMLAnchorElement>, 'color' | 'width' | 'height'>, BoxProps {
    as?: any
}

export const MenuLink: React.FC<CustomMenuLinkProps> = props => <ReachMenuLink as="a" {...props} />
