import React from 'react'
import addDays from 'date-fns/add_days'
import { HStack, VStack, StackProps } from './Layout'
import { MachineTimeline } from './Timeline'
import Box from './Box'
import Text from './Text'
import { Visibility, Sort } from './Icon'
import { isWithinRange, isSameDay, endOfDay, startOfDay } from 'date-fns'
import { useAsyncCallback } from '../hooks/useAsync'
import { timeline } from '../api'
import Confirm from './Confirm'
import { Translated } from '../context/LocaleContext'
import { Touchable } from './Button'
import { Menu, MenuButton, MenuList, MenuItem } from './Menu'
import useLocalStorage from '../hooks/useLocalStorage'
import Input from './Input'

interface MachinesProps extends StackProps {
    mode: 'cut' | 'edit' | 'erase'
    start: Date
    machines: Machine[]
    setMachines: (state: Machine[]) => void
    onMachinesUpdate: () => void
    end: Date
    onCut: () => void
}

const Machines: React.FC<MachinesProps> = ({
    mode,
    onCut,
    start,
    machines: inputMachines,
    setMachines,
    end,
    onMachinesUpdate,
    ...props
}) => {
    interface MoveActionPayload {
        actions: {
            description: string
            action: string
        }[]
        workorder: Order
        machineIndex: number
        newMachineIndex: number
    }

    const isCutting = mode === 'cut'
    const moveWorkOrder = useAsyncCallback(timeline.moveWorkorder)
    const [actionPayload, setActionPayload] = React.useState<MoveActionPayload | null>(null)
    const [showMachinesWithoutOrders, setShowMachinesWithoutOrders] = useLocalStorage(
        'ui-show-machines-without-orders',
        true
    )
    const [showMachineTimeline, setShowMachinTimeline] = useLocalStorage('ui-show-machine-timeline', true)

    const machines = inputMachines.filter(x => {
        if (!showMachinesWithoutOrders && x.orders.length === 0) {
            return false
        }
        return true
    })

    const onPositionChange = (
        start: Date,
        machineIndex: number,
        newMachineIndex: number,
        workOrderIndex: number,
        dayCount: number
    ) => {
        const newMachines = [...machines]
        const oldMachine = newMachines[machineIndex]
        const newMachine = newMachines[newMachineIndex]

        const startdate = startOfDay(start)
        const enddate = endOfDay(addDays(startdate, dayCount))

        const ref = oldMachine.orders[workOrderIndex]
        // Check if workorder is unchanged and cancel all actions
        if (machineIndex === newMachineIndex && isSameDay(start, ref.startdate) && isSameDay(enddate, ref.enddate)) {
            return
        }

        const oldWorkOrder: Order = {
            ...oldMachine.orders[workOrderIndex]
        }

        let collissions: Order[]

        const isNewMachine = newMachineIndex !== machineIndex && newMachine

        if (isNewMachine) {
            collissions = newMachine.orders.filter(x => {
                if (x.type !== oldWorkOrder.type) {
                    return false
                }

                return (
                    isWithinRange(startdate, x.startdate, x.enddate) ||
                    isWithinRange(enddate, x.startdate, x.enddate) ||
                    isWithinRange(x.startdate, startdate, enddate) ||
                    isWithinRange(x.enddate, startdate, enddate)
                )
            })
        } else {
            collissions = oldMachine.orders.filter((x, index) => {
                if (index === workOrderIndex || x.type !== oldWorkOrder.type) {
                    return false
                }
                return (
                    isWithinRange(startdate, x.startdate, x.enddate) ||
                    isWithinRange(enddate, x.startdate, x.enddate) ||
                    isWithinRange(x.startdate, startdate, enddate) ||
                    isWithinRange(x.enddate, startdate, enddate)
                )
            })
        }

        const beforeChangeWorkorder: Order = { ...oldWorkOrder }

        if (collissions.length > 0) {
            oldWorkOrder.timestamp = new Date().getTime()
            oldMachine.orders[workOrderIndex] = oldWorkOrder
            setMachines(newMachines)
        } else {
            oldWorkOrder.startdate = startdate
            oldWorkOrder.enddate = enddate
            oldWorkOrder.timestamp = new Date().getTime()
            oldWorkOrder.machine_id = newMachine.id

            if (oldWorkOrder.id !== -1) {
                moveWorkOrder
                    .execute(oldWorkOrder)
                    .then(updatedWorkorder => {
                        onOrderSaved(oldMachine, newMachine, newMachines, (updatedWorkorder as unknown) as Order)
                    })
                    .catch(e => {
                        if (e.actions) {
                            setActionPayload({
                                actions: e.actions,
                                workorder: oldWorkOrder,
                                machineIndex,
                                newMachineIndex
                            })
                        } else {
                            beforeChangeWorkorder.timestamp = new Date().getTime()
                            oldMachine.orders[workOrderIndex] = beforeChangeWorkorder
                            setMachines(newMachines)
                        }
                    })
            }
        }
    }

    const onMoveCancel = () => {
        const payload = actionPayload as MoveActionPayload
        const newMachines = [...machines]
        const machine = newMachines[payload.machineIndex]

        const orderIndex = machine.orders.findIndex(x => x.id === payload.workorder.id)

        if (orderIndex !== -1) {
            const order = machine.orders[orderIndex]
            order.timestamp = new Date().getTime()
            setMachines(newMachines)
            setActionPayload(null)
        }
    }

    const onMoveOk = () => {
        const payload = actionPayload as MoveActionPayload
        const newMachines = [...machines]
        const oldMachine = newMachines[payload.machineIndex]
        const newMachine = newMachines[payload.newMachineIndex]

        const action = payload.actions[0]

        moveWorkOrder.execute(payload.workorder, action).then(updatedWorkorder => {
            onOrderSaved(oldMachine, newMachine, newMachines, (updatedWorkorder as unknown) as Order)
            setActionPayload(null)
        })
    }

    const onOrderSaved = (
        oldMachine: Machine,
        newMachine: Machine,
        newMachines: Machine[],
        updatedWorkorder: Order
    ) => {
        const updatedIndex = oldMachine.orders.findIndex(x => x.id === updatedWorkorder.id)
        if (updatedWorkorder.machine_id !== oldMachine.id) {
            oldMachine.orders.splice(updatedIndex, 1)
            newMachine.orders.push(updatedWorkorder)
        } else {
            oldMachine.orders[updatedIndex] = updatedWorkorder
        }
        setMachines(newMachines)
        onMachinesUpdate()
    }

    const onWorkOrderCut = React.useCallback(
        (dayIndex: number, workOrderIndex: number, machineIndex: number) => {
            if (!isCutting) return

            const newMachines = [...machines]
            const machine = newMachines[machineIndex]
            const workOrder = { ...machine.orders[workOrderIndex] }

            const date = addDays(workOrder.startdate, dayIndex)

            timeline.cutWorkorder(workOrder, date).then(([newWorkorder, copy]) => {
                machine.orders.splice(workOrderIndex, 1)
                machine.orders.push(newWorkorder)
                machine.orders.push(copy)
                setMachines(newMachines)
                onCut()
            })
        },
        [machines, setMachines, onCut, isCutting]
    )

    return (
        <VStack alignSelf="flex-start" {...props}>
            {actionPayload && (
                <Confirm message={actionPayload.actions[0].description} onOk={onMoveOk} onCancel={onMoveCancel} />
            )}
            <HStack bg="offwhite" spacing={0}>
                <VStack spacing={1} width={230} px={3} display="flex" css={{ flexShrink: 0, flexGrow: 0 }} pb={11}>
                    <HStack spacing={0} justifyContent="space-between">
                        <HStack spacing={2} ml={40} height={24} alignItems="center">
                            <Touchable onClick={() => setShowMachinTimeline(!showMachineTimeline)}>
                                <Visibility color={showMachineTimeline ? 'black' : 'brownish-grey'} />
                            </Touchable>
                            <Menu>
                                <MenuButton variant="touchable">
                                    <Sort />
                                </MenuButton>
                                <MenuList>
                                    <Text px={2} py={2} mb={0} fontSize="xs" color="brownish-grey">
                                        {Translated('show_machines') + ':'}
                                    </Text>
                                    <MenuItem
                                        css={{ fontWeight: 'semibold', label: { cursor: 'pointer' } }}
                                        onSelect={() => {
                                            setShowMachinesWithoutOrders(true)
                                        }}
                                    >
                                        <Input.Radio checked={showMachinesWithoutOrders}>
                                            {Translated('all')}
                                        </Input.Radio>
                                    </MenuItem>
                                    <MenuItem
                                        css={{ fontWeight: 'semibold', label: { cursor: 'pointer' } }}
                                        onSelect={() => {
                                            setShowMachinesWithoutOrders(false)
                                        }}
                                    >
                                        <Input.Radio checked={showMachinesWithoutOrders === false}>
                                            {Translated('only_with_workorders')}
                                        </Input.Radio>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                        <Box height={24} display="flex" alignItems="center">
                            <Text
                                m={0}
                                fontWeight="semibold"
                                color="brownish-grey"
                                fontSize="xs"
                                lineHeight="normal"
                                css={{ textTransform: 'uppercase' }}
                            >
                                {Translated('machines')}
                            </Text>
                        </Box>
                    </HStack>
                    {showMachineTimeline &&
                        machines.map(machine => (
                            <Box
                                key={machine.id}
                                height={39}
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Text
                                    m={0}
                                    fontWeight="bold"
                                    color="black"
                                    css={{
                                        textTransform: 'uppercase',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {machine.name}
                                </Text>
                            </Box>
                        ))}
                </VStack>
                {showMachineTimeline && (
                    <MachineTimeline
                        start={start}
                        end={end}
                        mode={mode}
                        machines={machines}
                        onWorkOrderCut={onWorkOrderCut}
                        onPositionChange={onPositionChange}
                    ></MachineTimeline>
                )}
            </HStack>
        </VStack>
    )
}

export default Machines
