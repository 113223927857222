import React from 'react'
import { ThemeProvider } from 'emotion-theming'

import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import theme from './theme'
import TimelinePage from './pages/TimelinePage'
import AdminPage from './pages/AdminPage'
import UsersPage from './pages/UsersPage'
import MachinesPage from './pages/MachinesPage'
import Box from './components/Box'
import TeamsPage from './pages/TeamsPage'
import useAuth from './hooks/useAuth'
import Input from './components/Input'
import Button from './components/Button'
import Text from './components/Text'
import { Logo } from './components/Icon'
import EditMachinePage, { AddMachinePage } from './pages/EditMachine'
import EditUserPage, { CreateUserPage } from './pages/EditUser'
import LegalEntitiesPage from './pages/LegalEntities'
import EditLegalEntityPage, { AddLegalEntityPage } from './pages/EditLegalEntity'
import AbsencePage from './pages/AbsencePage'
import Loader from './components/Loader'
import { LoginUser } from './types/auth'
import { Translated } from './context/LocaleContext'
import ErrorBoundary from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'
import MachinesSummaryPage from './pages/MachinesSummaryPage'

export const UserContext = React.createContext<{
    user: LoginUser | null
    logout?: () => void
    updateLegalEntity: (x: number) => Promise<LoginUser>
}>(
    // @ts-ignore
    {
        user: null
    }
)

const App = () => {
    const { user: currentUser, error, matches, updateLegalEntity, logout, login } = useAuth()

    return (
        <UserContext.Provider value={{ user: currentUser, logout, updateLegalEntity }}>
            <Box minHeight="100vh" bg="white">
                <ThemeProvider theme={theme}>
                    {matches('validating') && <Loader />}
                    {(matches('loggedOut') || matches('login')) && (
                        <Box
                            bg="offwhite"
                            height="100vh"
                            width="100vw"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                as="form"
                                p={3}
                                onSubmit={(e: any) => {
                                    e.preventDefault()
                                    const username = e.target.username.value
                                    const password = e.target.password.value
                                    login(username, password)
                                }}
                                bg="white"
                                width={300}
                            >
                                <Box display="flex" justifyContent="center">
                                    <Logo mb={3} />
                                </Box>
                                <Input name="username" placeholder="Email" />
                                <Input name="password" placeholder="Password" type="password" />
                                {error && <Text color="red">{error.message}</Text>}
                                <Button variant="secondary" loading={matches('login')}>
                                    {Translated('login')}
                                </Button>
                            </Box>
                        </Box>
                    )}
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        {matches('loggedIn') && (
                            <Router>
                                <Switch>
                                    <Route path="/timeline" component={TimelinePage} />
                                    <Redirect exact from="/" to="/timeline" />
                                </Switch>
                                <Route
                                    path="/admin"
                                    render={() => {
                                        return (
                                            <AdminPage>
                                                <Switch>
                                                    <Route component={CreateUserPage} path="/admin/users/add" />
                                                    <Route component={EditUserPage} path="/admin/users/:id" />
                                                    <Route component={UsersPage} path="/admin/users" />
                                                    <Route component={AddMachinePage} path="/admin/machines/add" />
                                                    <Route component={MachinesSummaryPage} path="/admin/machines-summary" />
                                                    <Route component={EditMachinePage} path="/admin/machines/:id" />
                                                    <Route component={MachinesPage} path="/admin/machines" />
                                                    <Route component={AddLegalEntityPage} path="/admin/legal/add" />
                                                    <Route component={EditLegalEntityPage} path="/admin/legal/:id" />
                                                    <Route component={LegalEntitiesPage} path="/admin/legal" />
                                                    <Route component={TeamsPage} path="/admin/teams" />
                                                    <Route component={AbsencePage} path="/admin/absence" />
                                                    <Redirect to="/admin/users" />
                                                </Switch>
                                            </AdminPage>
                                        )
                                    }}
                                ></Route>
                            </Router>
                        )}
                    </ErrorBoundary>
                </ThemeProvider>
            </Box>
        </UserContext.Provider>
    )
}

export default App
